import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { TCaseManagementFilterParams } from 'features/cases-management/types/TCasePagination'
import { useEffect, useState } from 'react'
import IListOfItemsCursor from 'types/api/IListOfItemsCursor'
import { IDoctor } from 'types/IUser'

const MIN_SEARCH_LENGTH = 3

type Props = {
  /** Поисковый запрос, введенный пользователем. */
  searchTerm: string
  /** Параметры фильтрации. */
  filterParams: TCaseManagementFilterParams
  /** Текущая активная вкладка. */
  menuTab: ECaseTableType
  /** Список докторов. */
  doctors?: IListOfItemsCursor<IDoctor>
}
const useFilteredDoctors = ({ doctors, filterParams, menuTab, searchTerm }: Props) => {
  const [filteredDoctors, setFilteredDoctors] = useState(doctors?.content)

  useEffect(() => {
    const applyFilter = () => {
      if (!doctors || !doctors.content || menuTab !== ECaseTableType.ROUTING) {
        setFilteredDoctors([])
        return
      }

      let updatedDoctors = doctors.content

      // Применяем фильтр по поисковому запросу
      if (searchTerm.length >= MIN_SEARCH_LENGTH) {
        updatedDoctors = doctors.content.filter((doctor) =>
          doctor.fullname.toLowerCase().includes(searchTerm.toLowerCase()),
        )

        // Если ничего не найдено после поиска, возвращаем весь doctors.content
        if (updatedDoctors.length === 0) {
          updatedDoctors = doctors.content
        }
      }

      // Применяем фильтр по assignedDoctorUserId
      if (filterParams.assignedDoctorUserId && filterParams.assignedDoctorUserId.length > 0) {
        const assignedDoctorIds = new Set(filterParams.assignedDoctorUserId)
        const assignedDoctors = updatedDoctors.filter((doctor) => assignedDoctorIds.has(doctor.userId))
        const otherDoctors = updatedDoctors.filter((doctor) => !assignedDoctorIds.has(doctor.userId))

        // Создаем мапу для сортировки
        const idOrderMap = new Map(filterParams.assignedDoctorUserId.map((id, index) => [id, index]))

        assignedDoctors.sort((a, b) => (idOrderMap.get(a.userId) ?? 0) - (idOrderMap.get(b.userId) ?? 0))

        updatedDoctors = [...assignedDoctors, ...otherDoctors]
      }

      setFilteredDoctors(updatedDoctors)
    }

    applyFilter()
  }, [doctors, searchTerm, filterParams])

  return filteredDoctors
}

export default useFilteredDoctors
