import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import olMap from 'ol/Map'
import {
  selectAtlasViewerUrlSlideId,
  selectDefectsTabUrl,
  selectDefectsViewerUrlCaseId,
  selectTasksViewerUrlTaskId,
  selectUrlCaseId,
  selectUrlSlideId,
  VIEWERS_SET,
} from 'pages/viewer/model/viewerPageSlice'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ORIGIN_CASE_DATA_KEY, ORIGIN_CASES_DATA_KEY, VIEWING_STATE_KEY } from 'shared/lib/common/storageKeys'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { usePrevious } from 'shared/lib/hooks'
import { getFromLocalStorage } from 'shared/lib/local-storage'
import { DoubleClickZoom, DragPan, DragRotate, KeyboardPan, KeyboardZoom } from 'shared/lib/map/lib/interactions'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { IMapOl } from 'types/IMapOl'
import { IGroupType } from 'types/ISlide'
import ISource from 'types/ISource'
import { SettingsNameEnum } from 'types/IWorkspaces'
import TViewerId from 'types/TViewerId'
import { OriginCaseData } from 'viewer/tools/ui/all-cases/AllCasesContainer'

type IViewerPageContext = {
  isFastTravel: boolean
  setFastTravel: Dispatch<SetStateAction<boolean>>

  /** Открывает 1 вьювер */
  addViewer: (slideState: ISlideState) => void
  /** Открывает несколько вьюверов */
  addViewers: (slideStates: ISlideState[]) => void
  /** Удаляет 1 вьювер */
  removeViewer: (viewerId: TViewerId) => void
  /** Удаляет count вьювер-ов с конца */
  removeViewers: (count: number) => void
  /** Меняет слайд для переданного viewerId */
  changeViewerSlide: (viewerId: TViewerId, slideState: ISlideState) => void
  /** Меняет слайды для переданных viewerIds */
  changeViewersSlide: (viewerIds: TViewerId[], slideStates: ISlideState[]) => void

  resetViewersToSlide: (slideState: ISlideState) => void
  autoCoreg: boolean
  setAutoCoreg: Dispatch<SetStateAction<boolean>>
  handableCoreg: boolean
  setHandableCoreg: Dispatch<SetStateAction<boolean>>
  activeViewerId: TViewerId
  setActiveViewerId: Dispatch<SetStateAction<TViewerId>>
  viewerType: IViewerType
  viewingState: IViewingState
  /** Флаг при выходе из сплит вью по ПКМ */
  isAfterFastTravel?: boolean
  setIsAfterFastTravel: (value: boolean) => void
  /** Доступен ли переход к изначальному случаю */
  setIsOnRelatedCase: Dispatch<SetStateAction<boolean>>
  /** Меняет слайд для вьювера платформы */
  changePlatformSlide: (caseData?: OriginCaseData) => void
  /** Сброс всех корегистраций */
  resetCoregistration: () => void
}

type Props = {
  children: any
}

const ViewerPageContext = createContext<IViewerPageContext>({
  // getter - setter for specific mods
  activeViewerId: 'A',
  // viewer's control callbacks
  addViewer: () => {},
  addViewers: () => {},
  autoCoreg: false,
  changePlatformSlide: () => {},
  changeViewerSlide: () => {},
  changeViewersSlide: () => {},
  handableCoreg: false,
  isFastTravel: false,
  removeViewer: () => {},
  removeViewers: () => {},
  resetCoregistration: () => {},
  resetViewersToSlide: () => {},
  setActiveViewerId: () => {},
  setAutoCoreg: () => {},
  setFastTravel: () => {},
  setHandableCoreg: () => {},
  setIsAfterFastTravel: () => {},
  setIsOnRelatedCase: () => {},

  viewerType: 'DEFAULT',
  // main viewers map state
  viewingState: {},
})

type IViewerMode = 'DEFAULT' | 'SEARCH'
type IViewerType = 'DEFAULT' | 'ATLAS' | 'TASKS'

export type IViewingState = {
  [key in TViewerId]?: {
    slide: ISlideState
    map: IMapOl
  }
}
export type IViewingStateStorage = {
  [key in TViewerId]?: {
    slide: ISlideState
  }
}

export type ISlideState = {
  slideId: number
  caseId: number
  source: ISource
  viewerMode: IViewerMode
  slideGroupType: IGroupType
}

export type LocationState = {
  from?: string
}

export const ViewerPageProvider = ({ children }: Props) => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const { openViewerIds } = useOpenViewers()
  const atlasSlideId = useSelector(selectAtlasViewerUrlSlideId)
  const taskSlideId = useSelector(selectTasksViewerUrlTaskId)
  const slideDefectsCaseId = useSelector(selectDefectsViewerUrlCaseId)
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const bus = useEventBusProvided()
  const [isFastTravel, setFastTravel] = useState<boolean>(false)
  const [activeViewerId, setActiveViewerId] = useState<TViewerId>('A')
  const isAfterFastTravel = useRef<boolean>(false)
  const setIsAfterFastTravel = (value: boolean) => (isAfterFastTravel.current = value)
  const [autoCoreg, setAutoCoreg] = useState<boolean>(false)
  const [handableCoreg, setHandableCoreg] = useState<boolean>(false)
  const [isRelatedCase, setIsOnRelatedCase] = useState(false)
  const urlSlideId = useSelector(selectUrlSlideId)
  const urlCaseId = useSelector(selectUrlCaseId)
  const defectsTabUrl = useSelector(selectDefectsTabUrl)
  const type: IViewerType = atlasSlideId !== undefined ? 'ATLAS' : taskSlideId ? 'TASKS' : 'DEFAULT'

  const [viewerType, setViewerType] = useState<IViewerType>(type)
  useEffect(() => {
    if (atlasSlideId !== undefined && viewerType !== 'ATLAS') setViewerType('ATLAS')
    if (taskSlideId !== undefined && viewerType !== 'TASKS') setViewerType('TASKS')
    if (urlCaseId !== undefined && viewerType !== 'DEFAULT') setViewerType('DEFAULT')
  }, [viewerType, atlasSlideId, taskSlideId])

  const setMapInteractionsActivity = (map: olMap, value: boolean) => {
    map.getInteractions().forEach((interaction) => {
      if (interaction instanceof KeyboardPan || interaction instanceof KeyboardZoom) {
        interaction.setActive(value)
      }
    })
  }
  const createMap = (viewerId: TViewerId) => {
    const keyboardInteraction = new KeyboardPan({
      condition: (e: any) => !e.originalEvent.shiftKey,
      duration: 0,
      onEventProcessing: () => {
        bus.$emit('afterMapCenter', viewerId)
        bus.$emit('afterMapZoom', viewerId)
      },
      pixelDelta: 200,
    })
    const keyboardShiftInteraction = new KeyboardPan({
      condition: (e: any) => !!e.originalEvent.shiftKey,
      duration: 0,
      onEventProcessing: () => {
        bus.$emit('afterMapCenter', viewerId)
        bus.$emit('afterMapZoom', viewerId)
      },
      pixelDelta: 600,
    })
    const dragRotate = new DragRotate({
      onEventProcessing: () => {
        bus.$emit('afterMapRotation', viewerId)
      },
    })
    const doubleClickZoom = new DoubleClickZoom({
      onEventProcessing: () => {
        bus.$emit('afterMapZoom', viewerId)
      },
    })
    const keyboardZoom = new KeyboardZoom({
      onEventProcessing: () => {
        bus.$emit('afterMapZoom', viewerId)
      },
    })
    const drag = new DragPan({
      onEventProcessing: () => {
        bus.$emit('afterMapCenter', viewerId)
      },
    })
    const map = new olMap({
      interactions: [
        //@ts-ignore
        doubleClickZoom,
        //@ts-ignore
        drag,
        //@ts-ignore
        keyboardInteraction,
        //@ts-ignore
        keyboardShiftInteraction,
        //@ts-ignore
        dragRotate,
        //@ts-ignore
        keyboardZoom,
        // zoomInteraction,
      ],
      keyboardEventTarget: document,
      maxTilesLoading: 6,
    })
    map.getControls().forEach((control) => {
      map.removeControl(control)
    })
    return map
  }

  const updateMapInteractionsViewerId = (map: IMapOl, viewerId: TViewerId) => {
    map.getInteractions().forEach((interaction) => {
      if (interaction instanceof DragRotate) {
        interaction.onEventProcessing = () => bus.$emit('afterMapRotation', viewerId)
      } else if (interaction instanceof DragPan) {
        interaction.onEventProcessing = () => bus.$emit('afterMapCenter', viewerId)
      } else if (interaction instanceof KeyboardPan) {
        interaction.onEventProcessing = () => {
          bus.$emit('afterMapCenter', viewerId)
          bus.$emit('afterMapZoom', viewerId)
        }
      } else if (interaction instanceof DoubleClickZoom) {
        interaction.onEventProcessing = () => bus.$emit('afterMapZoom', viewerId)
      }
    })
    return map
  }

  /**
   * State:
   * Main binding between opened viewerIds and slides metadata, which used by child tree
   */

  const [viewingState, setViewingState] = useState<IViewingState>({
    A: {
      map: createMap('A'),
      slide: {
        caseId: urlCaseId || slideDefectsCaseId || NaN,
        slideGroupType: 'MICRO',
        slideId: type === 'DEFAULT' || type === 'TASKS' ? urlSlideId || NaN : atlasSlideId || NaN,
        source: type !== 'DEFAULT' ? 'ATLAS' : 'PLATFORM',
        viewerMode: 'DEFAULT',
      },
    },
  })

  const viewingStateStorage = localStorage.getItem(VIEWING_STATE_KEY) || ''
  const viewingStateStorageParse: IViewingStateStorage = viewingStateStorage ? JSON.parse(viewingStateStorage) : ''
  const viewerIds = useMemo(() => Object.keys(viewingState), [viewingState])

  /**Возвращает объект viewingState без свойства map.*/
  const removeMapProp = (obj: IViewingState): IViewingState =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        const { map, ...slide } = value
        return [key, slide]
      }),
    )

  const newViewingState = removeMapProp(viewingState)

  /**Сравнивает объект viewingState с объектом, хранящимся в локальном хранилище. */
  const checkSlide = (viewingFromStorage: IViewingStateStorage, viewingLocal: IViewingStateStorage): boolean => {
    const key = Object.keys(viewingLocal)[0] as TViewerId
    //@ts-ignore
    const { caseId, slideId } = viewingLocal[key].slide

    for (const key in viewingFromStorage) {
      if (Object.prototype.hasOwnProperty.call(viewingFromStorage, key)) {
        // @ts-ignore
        const slide = viewingFromStorage[key].slide

        if (slide.caseId === caseId && slide.slideId === slideId) {
          return false
        }
      }
    }

    return true
  }

  useEffect(() => {
    localStorage.setItem(VIEWING_STATE_KEY, JSON.stringify(newViewingState))
  }, [viewerIds])

  useEffect(() => {
    const compareViewingState = checkSlide(viewingStateStorageParse, newViewingState)
    if (compareViewingState) {
      localStorage.setItem(VIEWING_STATE_KEY, JSON.stringify(newViewingState))
    } else if (!compareViewingState && viewingStateStorageParse) {
      const res: any = {}
      Object.entries(viewingStateStorageParse).forEach(([key, value]) => {
        res[key] = {
          //Обработка уже существующей карты
          map: key === 'A' && viewingState['A']?.map ? viewingState['A']?.map : createMap(key as TViewerId),
          slide: value.slide,
        }
      })
      setViewingState(res)
    }
  }, [])

  const changeViewerSlide = useCallback(
    (viewerId: TViewerId, slideState: ISlideState) => {
      const changingState = viewingState[viewerId]
      if (!changingState) return
      changingState.map.setTarget(undefined)
      const newState: IViewingState = {}

      const changingIdx = VIEWERS_SET.findIndex((item) => item === viewerId)
      Object.keys(viewingState).forEach((item, idx) => {
        const vid = item as TViewerId
        if (idx === changingIdx) newState[vid] = { map: createMap(vid), slide: slideState }
        else newState[vid] = viewingState[vid]
      })
      setViewingState(newState)
    },
    [viewingState],
  )

  const changeViewersSlide = useCallback(
    (viewerIds: TViewerId[], slideStates: ISlideState[]) => {
      for (const key of viewerIds) {
        const viewerId = key as TViewerId
        viewingState[viewerId]?.map.setTarget(undefined)
      }
      const newState: IViewingState = {}

      Object.keys(viewingState).forEach((key) => {
        const viewerId = key as TViewerId
        const idx = viewerIds.findIndex((it) => it === viewerId)
        if (idx !== -1) {
          newState[viewerId] = { map: createMap(viewerId), slide: slideStates[idx] }
        } else {
          newState[viewerId] = viewingState[viewerId]
        }
      })
      setViewingState(newState)
    },
    [viewingState],
  )

  const resetViewersToSlide = useCallback(
    (slideState: ISlideState) => {
      Object.keys(viewingState).forEach((item, idx) => {
        const vid = item as TViewerId
        viewingState[vid]?.map.setTarget(undefined)
      })
      setViewingState({ A: { map: createMap('A'), slide: slideState } })
    },
    [viewingState],
  )

  const addViewer = useCallback(
    (slideState: ISlideState) => {
      const newViewerId = VIEWERS_SET[Object.keys(viewingState).length] as TViewerId
      if (newViewerId === undefined) return
      const newState: IViewingState = {
        ...viewingState,
        [newViewerId]: {
          map: createMap(newViewerId),
          slide: slideState,
        },
      }
      setViewingState(newState)
    },
    [viewingState],
  )

  const addViewers = useCallback(
    (slideStates: ISlideState[]) => {
      const startIdx = Object.keys(viewingState).length
      const newViewerIds = VIEWERS_SET.slice(startIdx, startIdx + slideStates.length) as TViewerId[]
      const newState: IViewingState = { ...viewingState }
      newViewerIds.forEach((viewerId, idx) => {
        newState[viewerId] = {
          map: createMap(viewerId),
          slide: slideStates[idx],
        }
      })
      setViewingState(newState)
    },
    [viewingState],
  )

  const removeViewer = useCallback(
    (removingViewerId: TViewerId) => {
      const newState: IViewingState = {}
      const openViewerIds = Object.keys(viewingState)
      // если вьюеров меньше двух - отменяем действие. На уровне рендера и так сделано, но предохранитель не помешает.
      if (openViewerIds.length < 2) return
      const rmIdx = openViewerIds.findIndex((item) => item === removingViewerId)
      // смотрим есть ли вообще в текущих вьюерах удаляемый и исключаем ошибку.
      if (rmIdx === -1) return
      // обязательно чистим императивно старую карту иначе будет треш
      const removingMap = viewingState[removingViewerId]?.map
      if (removingMap === undefined) return
      removingMap.setTarget(undefined)
      openViewerIds.forEach((item, index) => {
        const titem = item as TViewerId
        if (index === rmIdx) return
        else {
          const vState = viewingState[titem]
          if (vState === undefined) return
          const map = vState?.map
          const idx = index >= rmIdx ? index - 1 : index
          const newVid = VIEWERS_SET[idx] as TViewerId
          if (map !== undefined) {
            /** тут надо обновить viewer id, отправляемый эмиттером в кастомных interactions */
            newState[newVid] = { ...vState, map: updateMapInteractionsViewerId(map, newVid) }
          } else {
            newState[newVid] = { ...vState, map: createMap(newVid) }
          }
        }
      })
      setViewingState(newState)
    },
    [viewingState],
  )

  const removeViewers = useCallback(
    (count: number) => {
      const newState: IViewingState = {}
      const openViewerIds = Object.keys(viewingState)
      // если попытаемся закрыть вьюверов больше, чем их открыто
      if (openViewerIds.length - count < 1) return
      // обязательно чистим императивно старую карту иначе будет треш
      for (let i = 0; i < count; i++) {
        const removingViewerId = openViewerIds.pop() as TViewerId
        const removingMap = viewingState[removingViewerId]?.map
        if (removingMap === undefined) return
        removingMap.setTarget(undefined)
      }
      openViewerIds.forEach((item) => {
        //@ts-ignore
        newState[item as TViewerId] = { ...viewingState[item as TViewerId] }
      })
      setViewingState(newState)
    },
    [viewingState],
  )

  /**
   * Effect:
   * The place for binding state to change current url route
   */
  const workspaceId = useCurrentWorkspaceId()
  const firstSlideState = useMemo(() => viewingState['A']?.slide, [viewingState])
  const prevFirstSlideState = usePrevious(firstSlideState)
  // effect for platform source
  useEffect(() => {
    if (prevFirstSlideState === undefined || firstSlideState === undefined || firstSlideState.source === 'ATLAS') return
    const prevFirstSlideId = prevFirstSlideState.slideId
    const curFirstSlideId = firstSlideState.slideId
    const previousUrl = location?.state?.from
    let path = null

    if (slideDefectsCaseId) {
      path = `/defects-viewer/${firstSlideState.caseId}/${defectsTabUrl}/?slideId=${curFirstSlideId}`
    } else if (prevFirstSlideId !== curFirstSlideId) {
      path = `/workspace/${workspaceId}/viewer/${firstSlideState.caseId}`
      if (curFirstSlideId) {
        path += `?slideId=${curFirstSlideId}`
      }
    }

    if (path) {
      dispatch(push(path, { from: previousUrl }))
    }
  }, [viewingState])

  const handleSplitViewManagingKeys = useCallback(
    (e: any) => {
      if (Object.keys(viewingState).length === 1) return
      const recognizeNextViewerIdFromCurrent = () => {
        let next: TViewerId = 'A'
        let currentIndex: number | null = null
        for (let i = 0; i < VIEWERS_SET.length; i++) {
          const id = VIEWERS_SET[i] as TViewerId
          if (currentIndex === null) {
            if (id === activeViewerId) {
              currentIndex = i
              continue
            }
          } else {
            if (i === VIEWERS_SET.length) continue
            else if (i === currentIndex + 1) {
              next = id as TViewerId
              continue
            }
          }
        }
        return next
      }
      if (e.key === 'Tab') {
        if (isFastTravel && openViewerIds.length > 1) {
          document.exitPointerLock()
          setActiveViewerId(recognizeNextViewerIdFromCurrent())
          viewingState[activeViewerId]?.map.getViewport()?.requestPointerLock()
        } else {
          setActiveViewerId(recognizeNextViewerIdFromCurrent())
        }
      } else if (getBooleanOrDefaultAccess(SettingsNameEnum.EnableAiSlideCoreg, true, currentWorkspace)) {
        switch (e.code) {
          case 'KeyQ':
            // Включение-выключение ручной корегистрации
            setHandableCoreg(!handableCoreg)
            break
          case 'KeyW':
            // Включение-выключение автоматической корегистрации
            setAutoCoreg(!autoCoreg)
            break
        }
      }
    },
    [viewingState, activeViewerId, handableCoreg, autoCoreg, openViewerIds, isFastTravel],
  )

  useEffect(() => {
    document.addEventListener('keyup', handleSplitViewManagingKeys)
    return () => document.removeEventListener('keyup', handleSplitViewManagingKeys)
  }, [viewingState, activeViewerId, handableCoreg, autoCoreg, isFastTravel])

  /** Keep active viewer with actual viewing state and switching activity of its maps */
  useEffect(() => {
    const eachMapToReset = (excludedVid: TViewerId, cb: (map: olMap) => any) => {
      Object.keys(viewingState).forEach((key) => {
        const vid = key as TViewerId
        if (vid === excludedVid) return
        const current = viewingState[vid]
        if (current === undefined) return
        cb(current.map)
      })
    }
    const activeViewingState = viewingState[activeViewerId]
    if (activeViewingState !== undefined) {
      setMapInteractionsActivity(activeViewingState.map, true)
      eachMapToReset(activeViewerId, (map) => {
        setMapInteractionsActivity(map, false)
      })
    } else {
      const newActiveViewerId = 'A' as TViewerId
      const newActiveViewingState = viewingState[newActiveViewerId]
      if (newActiveViewingState === undefined) return
      setActiveViewerId(newActiveViewerId)
      setMapInteractionsActivity(newActiveViewingState.map, true)
      eachMapToReset(newActiveViewerId, (map) => {
        setMapInteractionsActivity(map, false)
      })
    }
  }, [viewingState, activeViewerId])

  useEffect(() => {
    /** Переключение между связанными случаями тригрерится переменной внутри провайдера, **/
    if (isRelatedCase) {
      const localOriginCaseData = getFromLocalStorage<OriginCaseData>(ORIGIN_CASE_DATA_KEY)
      if (localOriginCaseData) {
        resetCoregistration()
        setIsOnRelatedCase(false)
        changePlatformSlide(localOriginCaseData)
        localStorage.removeItem(ORIGIN_CASES_DATA_KEY)
        localStorage.removeItem(ORIGIN_CASE_DATA_KEY)
      }
    }
  }, [isRelatedCase])

  const resetCoregistration = () => {
    setAutoCoreg(false)
    setHandableCoreg(false)
  }

  const changePlatformSlide = (caseData?: OriginCaseData) => {
    const { caseId: localCaseId = urlCaseId, groupType = 'MICRO', path, slideId } = caseData || {}
    const viewerSlide: ISlideState = {
      caseId: localCaseId as number,
      slideGroupType: groupType,
      slideId: slideId as number,
      source: 'PLATFORM',
      viewerMode: 'DEFAULT',
    }
    if (viewerSlide.slideId && urlSlideId === viewerSlide.slideId) return
    dispatch(push(path || location.pathname))
    changeViewerSlide(activeViewerId, viewerSlide)
  }

  return (
    <ViewerPageContext.Provider
      value={{
        activeViewerId,
        addViewer,
        addViewers,
        autoCoreg,
        changePlatformSlide,
        changeViewerSlide,
        changeViewersSlide,
        handableCoreg,
        isAfterFastTravel: isAfterFastTravel.current,
        isFastTravel,
        removeViewer,
        removeViewers,
        resetCoregistration,
        resetViewersToSlide,
        setActiveViewerId,
        setAutoCoreg,
        setFastTravel,
        setHandableCoreg,
        setIsAfterFastTravel,
        setIsOnRelatedCase,
        viewerType,
        viewingState,
      }}
    >
      {children}
    </ViewerPageContext.Provider>
  )
}

export const useViewerPageProvided = () => useContext(ViewerPageContext)

export const useOpenViewers = () => {
  const { activeViewerId, viewingState } = useViewerPageProvided()
  const viewerIds = useMemo(() => Object.keys(viewingState), [viewingState])
  /**
   * Важное замечание. Система гарантирует совпадение через индексы.
   * Грубо говоря А - 0, B - 1, C - 2, D - 3. Они будут всегда соответствовать индексам массива values карты вьюеров.
   * Таким образом по индексам можно найти соответствующий вьюеру slide id даже не используя viewerId напрямую, а забрав через индекс.
   */
  return {
    activeViewerId,
    firstViewerId: viewerIds[0] as TViewerId,
    openViewerIds: viewerIds as TViewerId[],
  }
}

export const useViewerIdMap = (viewerId: TViewerId): olMap => {
  const { viewingState } = useViewerPageProvided()
  return useMemo(() => {
    const state = viewingState[viewerId]
    if (state === undefined) {
      return new olMap({
        keyboardEventTarget: document,
      })
    } else {
      return state.map
    }
  }, [viewingState, viewerId])
}
const defaultState: ISlideState = {
  caseId: NaN,
  slideGroupType: 'MICRO',
  slideId: NaN,
  source: 'PLATFORM',
  viewerMode: 'DEFAULT',
}
export const useViewerIdSlideState = (viewerId: TViewerId): ISlideState => {
  const { viewingState } = useViewerPageProvided()
  return useMemo(() => {
    const state = viewingState[viewerId]
    if (state === undefined) {
      const keys = Object.keys(viewingState)
      const key = keys[keys.length - 1] as TViewerId
      return viewingState[key]?.slide || defaultState
    }
    return state.slide
  }, [viewingState, viewerId])
}
