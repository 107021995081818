import { useCaseQuery } from 'features/cases/api/query'
import { useViewerContext } from 'pages/viewer/ui/ViewerContext'
import { ICaseRelation } from 'types/ICase'

import SlideUploadButtonContainer from './SlideUploadButtonContainer'
import ThumbnailContainer from './ThumbnailContainer'
import TusFileLoaderContainer from './TusFileLoaderContainer'
import SmallThumbnailsList from './ui/SmallThumbnailsList'
import Thumbnail from './ui/Thumbnail'

type Props = {
  caseId: number
}
const ThumbnailsListContainer = ({ caseId }: Props) => {
  const { data: caseRecord, refetch } = useCaseQuery({ caseId, source: 'PLATFORM' })
  const { filesList, isDefectModeViewer, slides, slidesIds } = useViewerContext()

  return (
    <SmallThumbnailsList>
      {!isDefectModeViewer && caseRecord?.relation === ICaseRelation.OWNER && (
        <SlideUploadButtonContainer
          slides={slides}
          disabled={slides?.length >= 20}
          caseId={caseRecord.caseId}
          key="addButton"
          buttonType={slidesIds.length || filesList.length ? 'icon' : 'primary'}
        />
      )}
      {filesList
        .map((id) => (
          <Thumbnail key={id}>
            <TusFileLoaderContainer tusFileId={id} key={id} />
          </Thumbnail>
        ))
        .reverse()}
      {slidesIds
        .map((slideId) => (
          <ThumbnailContainer
            refetch={refetch}
            slideId={slideId}
            caseId={caseId}
            key={slideId}
            isOwner={caseRecord?.relation === ICaseRelation.OWNER}
            data-testid={'thumbnail-delete-icon'}
            withDeleteButton
          />
        ))
        .reverse()}
    </SmallThumbnailsList>
  )
}

export default ThumbnailsListContainer
