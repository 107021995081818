import { ECaseCursor } from 'features/cases-management/types/ECaseTableType'
import { TOmitCaseManagementQueryParams } from 'features/cases-management/types/TCasePagination'
import {
  EDefectsTableType,
  SlideWithDefect,
  TDefectsFilterParams,
  TDefectsQueryParams,
} from 'features/defects/types/TDefectsPagination'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQueryClient,
} from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'

import defectsService from './service'

export const useDefectsTableQuery = (
  tableType: EDefectsTableType,
  size: number,
  queryParams?: TDefectsQueryParams | TOmitCaseManagementQueryParams,
  cursor?: string,
  filterParams?: TDefectsFilterParams,
  options?:
    | UseInfiniteQueryOptions<
        IListOfItemsCursorCases<SlideWithDefect> | undefined,
        unknown,
        SlideWithDefect[],
        IListOfItemsCursorCases<SlideWithDefect> | undefined,
        QueryKey
      >
    | undefined,
) => {
  const wsId = Number(useCurrentWorkspaceId())

  const defectsManagementQuery = useInfiniteQuery<
    IListOfItemsCursorCases<SlideWithDefect> | undefined,
    unknown,
    SlideWithDefect[]
  >(
    [QUERY_TYPE.DEFECTS_SLIDES, tableType, JSON.stringify(queryParams), JSON.stringify(filterParams), size, wsId],
    async (data) =>
      await defectsService.fetchDefectsSlides(
        wsId,
        tableType,
        size,
        queryParams,
        !data.pageParam && cursor === ECaseCursor.NULL ? undefined : data.pageParam || cursor,
        filterParams,
      ),
    {
      getNextPageParam: (lastPage) => lastPage?.nextPageCursor,
      getPreviousPageParam: (firstPage) => firstPage?.previousPageCursor,
      refetchIntervalInBackground: true,
      refetchOnMount: 'always',
      select: (data): InfiniteData<SlideWithDefect[]> => ({
        pageParams: data.pages?.map((list) => {
          if (list) {
            const { content, ...params } = list
            return params
          }
        }),
        pages: data.pages.map((list) => list?.content ?? []),
      }),
      ...options,
    },
  )
  const queryClient = useQueryClient()
  const pages = defectsManagementQuery.data?.pages || []
  const defectsList = pages.flatMap((page) => page)

  defectsList.forEach((defect) => {
    queryClient.setQueryData([QUERY_TYPE.DEFECT, defect.slide.slideId], () => defect)
  })

  return {
    ...defectsManagementQuery,
    defectsList,
  }
}

export const useMarkSlidesAsViewedMutation = (wsId: number) => {
  const queryClient = useQueryClient()

  return useMutation(async (slideIds: number[]) => defectsService.markSlidesAsViewed(wsId, slideIds), {
    onError: (error) => {
      throw error
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_TYPE.DEFECTS_SLIDES])
    },
  })
}
