import { useTypedSelector } from 'app/redux/lib/selector'
import { useChangeAnnotationMutation } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { PlatformAnnotationListItemContainer } from 'features/annotations/ui/platform/PlatformAnnotationListItemContainer'
import { ISizeInfo } from 'features/annotations/ui/tasks/AnnotationsTasksContainer'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { memo, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useQueryClient } from 'react-query'
import AutoSizer from 'react-virtualized-auto-sizer'
import { areEqual, VariableSizeList as List } from 'react-window'
import { QUERY_TYPE } from 'shared/api'
import { useKeyPress, useOS } from 'shared/lib/hooks'
import { getSlideBarcode } from 'shared/lib/metadata'
import { IconElement, SpinElement, TooltipElement } from 'shared/ui/kit'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { customTruncateStringWithEllipsis, reduceElementsHeight } from 'viewer/tools'

import {
  LEFT_PADDING_PIXELS,
  MIN_PLATFORM_SLIDE_TITLE_HEIGHT,
  PLATFORM_ANNOTATION_HEIGHT,
  PLATFORM_SLIDE_TITLE_HEIGHT,
  TOGGLE_ICON,
} from './constants'
import { hasAnnotations, reorder } from './helpers'
import {
  CollapsedSlides,
  CollapseHeadProps,
  ListChildComponentProps,
  PlatformAnnotationsListProps,
} from './PlatformAnnotationsList.types'
import { AnnotationListContainer, DraggableWrapper, StyledCollapseTitle } from './style'

export const useWatchKeyPress = (viewerId: TViewerId) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const pressed = useKeyPress([`${useOS() === 'MacOS' ? 'Meta' : 'Control'}`, 'Shift'])
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  useEffect(() => {
    viewerDispatch(viewerSlice.actions.setAnnotationMultiSelect(annotationType === AnnotationType.POINT || pressed))
  }, [pressed])
}

export const PlatformAnnotationsList = memo(({ annotations, caseId, slideId }: PlatformAnnotationsListProps) => {
  const queryClient = useQueryClient()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const { mutateAsync: editAnnotation } = useChangeAnnotationMutation({ caseId, slideId })

  useWatchKeyPress(viewerId)

  const [isLoading, setIsLoading] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0)

  const [collapsedSlides, setCollapsedSlides] = useState<CollapsedSlides>(() => {
    const initCollapsedSlides: CollapsedSlides = {}
    annotations?.forEach((item) => {
      initCollapsedSlides[item.slideId] = true
    })
    return initCollapsedSlides
  })

  useEffect(() => {
    setCollapsedSlides((prev) => ({
      ...prev,
      [slideId]: !collapsedSlides?.[slideId] ? !prev[slideId] : true,
    }))
  }, [annotations])

  const reversedAnnotations = annotations?.reduce((list: Array<IAnnotation | string>, annotationGroup) => {
    const slideId = annotationGroup.slideId
    if (!collapsedSlides[slideId]) {
      return [...list, slideId.toString()]
    }
    return [...list, slideId.toString(), ...annotationGroup.annotations]
  }, [])

  useEffect(() => {
    listRef.current?.resetAfterIndex(0)
  }, [reversedAnnotations])

  const ref = useRef<List | null>(null)

  const onDragEnd = async (result: DropResult, provider: ResponderProvided) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index
    // dropped outside the list
    if (
      !destinationIndex ||
      !reversedAnnotations ||
      typeof reversedAnnotations[sourceIndex] === 'string' ||
      typeof reversedAnnotations[destinationIndex] === 'string'
    )
      return

    const sourceAnnotation = reversedAnnotations[sourceIndex] as IAnnotation
    const destinationAnnotation = reversedAnnotations[destinationIndex] as IAnnotation
    if (
      sourceAnnotation.slideId !== destinationAnnotation.slideId ||
      slideId !== sourceAnnotation.slideId ||
      slideId !== destinationAnnotation.slideId
    )
      return

    const slideAnnotations = reversedAnnotations
      .filter((el) => typeof el !== 'string' && el.slideId === slideId)
      .reverse()

    const newSourceIndex = slideAnnotations.findIndex(
      (el) => typeof el !== 'string' && el.slideAnnotationId === sourceAnnotation.slideAnnotationId,
    )
    const newDestinationIndex = slideAnnotations.findIndex(
      (el) => typeof el !== 'string' && el.slideAnnotationId === destinationAnnotation.slideAnnotationId,
    )
    const items = reorder(slideAnnotations, newSourceIndex, newDestinationIndex).reverse()
    // @ts-ignore
    ref?.current?.children?.length &&
      // @ts-ignore
      ref?.current?.children[0] &&
      // @ts-ignore
      setScrollOffset(ref?.current?.children[0]?.offsetTop + 82)
    //"smart" update
    await Promise.all(
      items.map(async (item, index) => {
        if (typeof item === 'string') return
        const annotationZindex = item.zindex
        const reorderedZindex = index + 1
        if (annotationZindex !== reorderedZindex) {
          const data = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, item.slideAnnotationId])
          if (data?.data) {
            await editAnnotation({
              ...item,
              data: {
                ...data.data,
              },
              zindex: reorderedZindex,
            })
          }
        }
      }),
    )
  }

  const getItemSize = (index: number) => {
    const item = reversedAnnotations?.[index]
    if (typeof item === 'string') {
      return hasAnnotations(annotations, +item) ? PLATFORM_SLIDE_TITLE_HEIGHT : MIN_PLATFORM_SLIDE_TITLE_HEIGHT
    }
    return PLATFORM_ANNOTATION_HEIGHT
  }

  // ref for scroll
  const listRef = useRef<List<any>>(null)

  useEffect(() => {
    if (!selectedAnnotationsIds || selectedAnnotationsIds.length !== 1) return
    const index = reversedAnnotations?.findIndex((it: any) => it?.slideAnnotationId === selectedAnnotationsIds[0])
    if (index === undefined || !ref.current) return
    listRef.current?.scrollToItem(index, 'smart')
  }, [selectedAnnotationsIds])

  const CollapseTitle = ({ collapsedSlides, setCollapsedSlides, slideId, style }: CollapseHeadProps) => {
    const queryClient = useQueryClient()
    const slideData = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
    const toggleIsOpen = () => {
      setCollapsedSlides({ ...collapsedSlides, [slideId]: !collapsedSlides[slideId] })
    }
    /** Селектор правой панели */
    const rightPanelEl = document.querySelector('.rightPanel') as HTMLElement
    /** Селектор блока со списком аннотаций */
    const annotationListContent = (document.querySelector('.annotation-list > div') as HTMLDivElement) || document.body
    /** Суммарная высота элементов правой панели без блока с аннотациями */
    const rightPanelHeightWithoutAnnotationContainer = reduceElementsHeight<HTMLDivElement>([
      '#right-panel-top-divider',
      '#right-panel-bottom-elements',
      '#viewer-right-panel-title',
    ])

    /** Флаг на видимость скроллбара */
    const [hasScrollbar, setHasScrollbar] = useState<boolean>(
      annotationListContent?.clientHeight > rightPanelEl?.clientHeight - rightPanelHeightWithoutAnnotationContainer,
    )

    useEffect(() => {
      /** Если высота контента с аннотациями больше высоты правой панели, считаем что скроллбар появился*/
      setHasScrollbar(
        annotationListContent?.clientHeight > rightPanelEl?.clientHeight - rightPanelHeightWithoutAnnotationContainer,
      )
    }, [annotationListContent?.clientHeight])

    const filename = getSlideBarcode(slideData)
    const colorCode = slideData?.stain?.shortName
    const truncatedFilename =
      filename &&
      customTruncateStringWithEllipsis(
        colorCode ? `${colorCode} ${filename}` : filename,
        hasScrollbar,
        rightPanelEl,
        TOGGLE_ICON + LEFT_PADDING_PIXELS,
        LEFT_PADDING_PIXELS,
      )

    return (
      <TooltipElement placement={'left'} title={filename} mouseEnterDelay={0.8}>
        <StyledCollapseTitle style={style} onClick={toggleIsOpen}>
          <IconElement
            name={collapsedSlides[slideId] ? 'sectionIsOpen' : 'sectionIsClose'}
            fill={'#99989F'}
            style={{ minHeight: `${TOGGLE_ICON}px`, minWidth: `${TOGGLE_ICON}px` }}
          />

          <div
            id="slidename"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {truncatedFilename}
          </div>
        </StyledCollapseTitle>
      </TooltipElement>
    )
  }

  const Row = memo((props: ListChildComponentProps) => {
    const { data, index, style } = props
    const annotation = data.reversedAnnotations ? data.reversedAnnotations[index] : undefined

    if (!annotation) {
      return null
    }

    if (typeof annotation === 'string') {
      const slideId = +annotation

      return hasAnnotations(annotations, slideId) ? (
        <CollapseTitle
          slideId={slideId}
          collapsedSlides={collapsedSlides}
          setCollapsedSlides={setCollapsedSlides}
          style={style}
        />
      ) : null
    }

    const hoverHandler = (annotationId: number) => {
      viewerDispatch(annotationsSlice.actions.setHoveredAnnotationId(annotationId))
    }
    const leaveHandler = () => {
      viewerDispatch(annotationsSlice.actions.setHoveredAnnotationId())
    }
    return (
      <Draggable draggableId={`${annotation.slideAnnotationId}`} index={index} key={annotation.slideAnnotationId}>
        {(provided) => (
          <DraggableWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
            onMouseLeave={() => leaveHandler()}
            onMouseEnter={() => hoverHandler(annotation.slideAnnotationId)}
          >
            <AnnotationListContainer>
              <PlatformAnnotationListItemContainer key={annotation.slideAnnotationId} annotation={annotation} />
            </AnnotationListContainer>
          </DraggableWrapper>
        )}
      </Draggable>
    )
  }, areEqual)

  return (
    <DragDropContext
      onDragEnd={(result, provided) => {
        setIsLoading(true)
        onDragEnd(result, provided).finally(() => setIsLoading(false))
      }}
    >
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          const annotation = reversedAnnotations ? reversedAnnotations[rubric.source.index] : undefined
          if (!annotation || typeof annotation === 'string') return <div>no item</div>
          return (
            <DraggableWrapper
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
            >
              <PlatformAnnotationListItemContainer key={annotation.slideAnnotationId} annotation={annotation} />
            </DraggableWrapper>
          )
        }}
      >
        {(droppableProvided, droppableSnapshot) => {
          const itemCount: number = droppableSnapshot.isUsingPlaceholder
            ? (reversedAnnotations?.length || 0) + 1
            : reversedAnnotations?.length || 0

          return (
            <>
              {isLoading && (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '64px',
                    justifyContent: 'center',
                    padding: '16px 0',
                    position: 'absolute',
                    right: '0px',
                    top: '-58px',
                    width: '64px',
                    zIndex: '2',
                  }}
                >
                  <SpinElement />
                </div>
              )}
              <AutoSizer defaultHeight={100} defaultWidth={1}>
                {({ height, width }: Required<ISizeInfo>) => (
                  <List
                    className="annotation-list"
                    height={height}
                    itemCount={itemCount}
                    itemSize={getItemSize}
                    width={width}
                    ref={listRef}
                    outerRef={ref}
                    innerRef={droppableProvided.innerRef}
                    itemData={{ reversedAnnotations }}
                    initialScrollOffset={scrollOffset}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
})
