import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery, useCaseSlideGrouped } from 'features/cases/api/query'
import { useEffect } from 'react'
import { usePrevious } from 'shared/lib/hooks'

export const useSlideGrouped = (caseId: number) => {
  const { refetch } = useCaseQuery({ caseId, source: 'PLATFORM' })
  const { data, isLoading: isLoadingCaseGrouped, refetch: refetchCaseGrouped } = useCaseSlideGrouped(caseId)
  const filesList = useTypedSelector((state) => state.tusFiles.listByCase[caseId]) || []
  const prevFilesList = usePrevious(filesList) || []

  useEffect(() => {
    if (prevFilesList.length - 1 === filesList.length) {
      refetch()
      refetchCaseGrouped()
    }
  }, [filesList])

  useEffect(() => {
    refetchCaseGrouped()
  }, [caseId])

  const slidesIds = data?.flatMap((item) => item.slides.map((slide) => slide.slideId)) || []
  const slides = data?.flatMap((item) => item.slides) || []

  return {
    filesList,
    isLoadingCaseGrouped,
    refetchCaseGrouped,
    slideGrouped: data,
    slides,
    slidesIds,
  }
}
