import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import RightPanelContainer from 'pages/viewer/ui/common/RightPanelContainer'
import TopToolbarContainer from 'pages/viewer/ui/common/TopToolbarContainer'
import ViewerLayout from 'pages/viewer/ui/common/ViewerLayout'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'

import DefectsLeftPanelContainer from './tree/DefectsLeftPanelContainer'

const DefectsViewerContainer = () => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const { isDefectsVisible } = useSettingsAndUserRoles()

  useEffect(
    () => () => {
      dispatch(viewerPageSlice.actions.resetAllViewersStateExceptArtefacts())
    },
    [],
  )

  return isDefectsVisible ? (
    <ViewerLayout
      leftRef={ref.current}
      topSlot={<TopToolbarContainer />}
      leftSlot={<DefectsLeftPanelContainer leftRef={ref} />}
      rightSlot={<RightPanelContainer />}
    />
  ) : null
}

export default DefectsViewerContainer
