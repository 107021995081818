import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseReferencesQuery } from 'features/cases/api/query'
import { getCountDefects } from 'features/thumbnails/common/utils'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getWorkspaceIdUrl } from 'shared/lib/workspaces'
import { IconElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { useViewerDispatch, viewerSlice } from 'viewer/container'
import { getSlideSummary, isAllSlides, isUnviewedSlide } from 'viewer/map/layers/slide/helpers'

type SlideCountersProps = {
  caseId: number
}

export const SlideCounters: React.FC<SlideCountersProps> = ({ caseId }) => {
  const { t } = useTranslation()
  const { slides } = useSlideGrouped(caseId)
  const workspaceId =
    useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId ||
    getWorkspaceIdUrl(window.location.href)
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(viewerId)
  const unviewedSlides = useTypedSelector((state) => state.viewers[viewerId].viewer.unviewedSlides)

  const slideSummary = getSlideSummary(slides, caseReferencesSlides)
  const defectsCount = getCountDefects(slides)

  const areAllSlidesEqual = isAllSlides(slides, caseReferencesSlides)

  useEffect(() => {
    viewerDispatch(
      viewerSlice.actions.setUnviewedSlides(
        slides.reduce((count, slide) => (isUnviewedSlide(slide) ? count + 1 : count), 0),
      ),
    )
  }, [caseId])

  return (
    <>
      <TooltipElement placement="topLeft" title={t('Загруженные микропрепараты')}>
        <CountGroup>
          <StyledIcon isAllSlides={areAllSlidesEqual} size={'md'} name={'imagesSmall'} />
          {!!slideSummary && <TitleCount>{slideSummary}</TitleCount>}
        </CountGroup>
      </TooltipElement>
      {unviewedSlides > 0 && (
        <TooltipElement placement="topLeft" title={<p>{t('Непросмотренные микропрепараты')}</p>}>
          <CountGroup>
            <IconElement fill="var(--color-blue)" size="md" name="newSlideSmall" />
            <TitleCount>{unviewedSlides}</TitleCount>
          </CountGroup>
        </TooltipElement>
      )}
      {!!defectsCount && (
        <CountGroup>
          <StyledIcon size={'md'} name={'defectSlideSmall'} />
          <TitleCount>{defectsCount}</TitleCount>
        </CountGroup>
      )}
    </>
  )
}

const CountGroup = styled.div`
  display: flex;
  gap: 4px;
  padding-right: 8px;
`

const StyledIcon = styled(IconElement)<{ isAllSlides?: boolean }>`
  color: ${({ isAllSlides }) => (isAllSlides ? 'var(--color-text-3)' : 'var(--color-red)')};
`
const TitleCount = styled(TitleElement)`
  font-size: 12px !important;
  line-height: 16px !important;
`
