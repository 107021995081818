import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useSlideViewedUpdate } from 'entities/slide/api/query'
import { useUserActivityContext } from 'features/multiplayer/lib'
import { useFollowActiveUser } from 'features/multiplayer/ui/follow-user'
import { throttle } from 'lodash'
import { MapBrowserEvent } from 'ol'
import TileLayer from 'ol/layer/Tile'
import olMap from 'ol/Map'
import { ObjectEvent } from 'ol/Object'
import { Projection } from 'ol/proj'
import RenderEvent from 'ol/render/Event'
import View from 'ol/View'
import { useMapParamsProvided } from 'pages/viewer/lib/common/MapsProvider'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { fromDegreesToRadians } from 'pages/viewer/lib/helper'
import { selectAtlasViewerUrlSlideId, selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useViewerContext } from 'pages/viewer/ui/ViewerContext'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useEventBusProvided } from 'shared/lib/EventBus'
import useContextMenu from 'shared/lib/hooks/useContextMenu'
import { MapContext } from 'shared/lib/map'
import ScaleLine from 'shared/lib/map/lib/controls/ScaleLine'
import { useMapProvided } from 'shared/lib/map/ui/MapProvider'
import { getObjectivePower, getResolutionByZoomLevel, getSlideMppx } from 'shared/lib/metadata'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useSlideMapViewSelector, useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { FastTravelInteraction } from 'viewer/map/lib/interactions/FastTravelInteraction'
import MouseWheelZoomInteraction from 'viewer/tools/ui/zoom/MouseWheelZoomInteraction'

import { getFactorsByViewerId } from './helpers'
import { isUnviewedSlide } from './slide/helpers'
import { adjustScaleIfRequired } from './slide/helpers/scaledIIIOptions'
import { SlideLayerContext, useSlideLayerProvided } from './slide/SlideLayerProvider'

const PROJ_COEFFICIENT = 0.000001
const MAX_SLIDE_ZOOM = 80
/** CONTEXT_MENU_MARGIN - Отступы окон контекстного меню от края экрана */
const CONTEXT_MENU_MARGIN = 15
/** MIDDLE_MOUSE_NUMBER - Номер средней кнопки мыши */
const MIDDLE_MOUSE_NUMBER = 2
/** RIGHT_MOUSE_NUMBER - Номер правой кнопки мыши */
const RIGHT_MOUSE_NUMBER = 3
/** DIVISOR_NUMBER - Число делитель */
const DIVISOR_NUMBER = 2
const SLIDE_VIEWED_UPDATE_THROTTLE = 2000

const MapViewContainer = () => {
  const { config } = useContext(SlideLayerContext)
  const { map, viewerId } = useContext(MapContext)
  return config && viewerId && map ? <MapView viewerId={viewerId} map={map} /> : null
}
type Props = {
  viewerId: TViewerId
  map: olMap
}
const MapView = ({ map, viewerId }: Props) => {
  const { caseId, slideGroupType, slideId, source } = useViewerIdSlideState(viewerId)
  const { viewCenter, viewRotation, viewZoom } = useSlideMapViewSelector({ slideId, viewerId })
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const isVisibleScale = slide?.slideMetadata?.commonMetadata?.mppX
  const { config } = useSlideLayerProvided()
  const queryClient = useQueryClient()
  const viewerDispatch = useViewerDispatch(viewerId)
  const unviewedSlides = useTypedSelector((state) => state.viewers[viewerId].viewer.unviewedSlides)

  const onSlideViewedUpdateError = () => {
    queryClient.setQueryData([QUERY_TYPE.SLIDE, slideId], () => ({ ...slide, viewed: false }))
  }

  const { isLoading: isUpdatingSlideViewed, mutate: updateSlideViewedMutate } = useSlideViewedUpdate(
    caseId,
    slideId,
    onSlideViewedUpdateError,
  )
  const throttledUpdateSlideViewedMutate = throttle(updateSlideViewedMutate, SLIDE_VIEWED_UPDATE_THROTTLE)

  const { openViewerIds } = useOpenViewers()
  const { activeViewerId, autoCoreg, handableCoreg, isFastTravel, setIsAfterFastTravel, viewingState } =
    useViewerPageProvided()
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const { setCurrentParam, setOriginParams } = useMapParamsProvided()
  const bus = useEventBusProvided()
  const { setMapBbox } = useMapProvided()
  const { changeMode, drawMode } = useTypedSelector((state) => state.annotations)
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType)

  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const atlasId = useSelector(selectAtlasViewerUrlSlideId)
  const { isDefectModeViewer } = useViewerContext()
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const { contextMenuHandler } = useContextMenu({
    caseId,
    map,
    menuMargin: CONTEXT_MENU_MARGIN,
    selectedAnnotationsIds,
    slideId,
    viewerId,
  })
  const { publishUserActivity } = useUserActivityContext()

  const colorCode = slide?.stain?.shortName

  useFollowActiveUser(viewerId)

  useEffect(() => {
    const scaleLine = map
      .getControls()
      .getArray()
      .filter((control) => control instanceof ScaleLine)[0]
    if (slideGroupType === 'MACRO') {
      map.removeControl(scaleLine)
    } else if (isVisibleScale) {
      map.addControl(
        //@ts-ignore
        new ScaleLine({ colorCode }),
      )
    }
  }, [slideGroupType, slideId, isVisibleScale])

  useEffect(() => {
    const scaleLine = map
      .getControls()
      .getArray()
      .filter((control) => control instanceof ScaleLine)[0]

    if (slideGroupType !== 'MACRO' && colorCode) {
      //@ts-ignore
      scaleLine?.setColorCode(colorCode)
    }
  }, [colorCode, slideGroupType])

  const updateBbox = () => {
    const size = map.getSize()
    const boxcoords = map.getView().calculateExtent(size)
    const bbox = { x1: boxcoords[0], x2: boxcoords[2], y1: boxcoords[1], y2: boxcoords[3] }
    setMapBbox(bbox)
  }

  /** Необходимо локальное запоминание значений центра слайда для применения актуального значения, т.к. config слайда и viewCenter слайда меняются асинхронно */
  const [memoSlideCenter, setMemoSlideCenter] = useState<number[]>()
  const isNewCenter = (memoCenter?: number[], currentCenter?: number[]) =>
    memoCenter && currentCenter && memoCenter[0] === currentCenter[0]
  /** When config was changed or created - from mapView. */
  useEffect(() => {
    if (!config) return
    const extent = config?.source.getTileGrid().getExtent()
    const size = config && config.options.size.length === 2 ? (config.options.size as [number, number]) : undefined
    const originalZoomSlide = getObjectivePower(slide)
    const minResolution = originalZoomSlide / MAX_SLIDE_ZOOM
    const resolutions = [...config.source.getTileGrid().getResolutions(), 0.5, minResolution]

    const view = new View({
      constrainOnlyCenter: true,
      constrainRotation: false,
      extent: !autoCoreg ? extent : undefined,
      projection: new Projection({
        code: 'EPSG:3857',
        getPointResolution: (res: any) => {
          const mppX = getSlideMppx(slide)
          return mppX ? mppX * res * PROJ_COEFFICIENT : 0
        },
      }),
      resolutions: adjustScaleIfRequired(resolutions),
      rotation: 0,
      showFullExtent: true,
      smoothExtentConstraint: true,
    })

    map.setView(view)
    map.getView().fit(extent)

    const mapZoom = map.getView().getResolution()
    if (size)
      setOriginParams(viewerId, {
        origCenter: [size[0] / 2, size[1] / -2],
        origRotation: 0,
        origZoom: mapZoom || 1,
      })
    /** Setting Center */
    if (viewCenter === undefined) {
      const mapCenter = map.getView().getCenter()
      /** Проверяем состояние Center для предотвращения ненужного центрирования */
      if (isNewCenter(memoSlideCenter, mapCenter)) return
      setCurrentParam(viewerId, 'center', mapCenter)
      mapCenter && setMemoSlideCenter(mapCenter)
    } else {
      map.getView().setCenter(viewCenter)
      setCurrentParam(viewerId, 'center', viewCenter, true)
      setMemoSlideCenter(viewCenter)
    }
    /** Setting Zoom */
    if (viewZoom === undefined) {
      const mapZoom = map.getView().getResolution()
      setCurrentParam(viewerId, 'zoom', mapZoom)
    } else {
      map.getView().setResolution(viewZoom)
      setCurrentParam(viewerId, 'zoom', viewZoom, true)
    }
    /** Setting Rotation */
    if (viewRotation === undefined) {
      const mapRotation = map.getView().getRotation()
      setCurrentParam(viewerId, 'rotation', mapRotation)
    } else {
      map.getView().setRotation(viewRotation)
      setCurrentParam(viewerId, 'rotation', viewRotation, true)
    }

    map.updateSize()
    updateBbox()
  }, [config])

  const handleChangeCenter = (e: ObjectEvent) => {
    const target = e.target as View
    if (!target) return

    setCurrentParam(viewerId, 'center', target.getCenter())
    updateUserActivityData(target)
  }

  const handleChangeZoom = (e: ObjectEvent) => {
    const target = e.target as View
    const zoom = target?.getResolution()

    if (!zoom) return
    setCurrentParam(viewerId, 'zoom', zoom)
    updateUserActivityData(target)
  }

  const handleChangeRotation = (e: ObjectEvent) => {
    const target = e.target as View
    if (!target) return

    const rotation = target.getRotation()

    /** Обновляем значение поворота слайда для текущего активного вьювера */
    setCurrentParam(viewerId, 'rotation', rotation)
    updateUserActivityData(target)
  }

  /** Tile Layer prerender */

  const tileLayer = useMemo(() => map.getAllLayers().filter((layer) => layer instanceof TileLayer)[0], [map])
  const onLayerPrerender = useCallback(
    (evt: any) => {
      const zoom = map.getView().getResolution()
      if (!zoom) return
      const zoomLvl = getResolutionByZoomLevel(zoom, slide)
      if (zoomLvl > 7) {
        evt.context.imageSmoothingEnabled = true
        evt.context.webkitImageSmoothingEnabled = true
        evt.context.mozImageSmoothingEnabled = true
        evt.context.msImageSmoothingEnabled = true
      } else {
        evt.context.imageSmoothingEnabled = false
        evt.context.webkitImageSmoothingEnabled = false
        evt.context.mozImageSmoothingEnabled = false
        evt.context.msImageSmoothingEnabled = false
      }
    },
    [slide],
  )

  /** Part of listeners from MapView */

  const onPointerDown = (e: MouseEvent) => {
    const mapSize = map.getSize()
    const isMiddleMouse = e.which === MIDDLE_MOUSE_NUMBER
    const isRightMouse = e.which === RIGHT_MOUSE_NUMBER

    if (drawMode || selectedAnnotationsIds.length > 0 || MITOSIS.isVisible) return

    const [width, height] = mapSize || []
    const [xFactor, yFactor] = getFactorsByViewerId(activeViewerId, openViewerIds.length)

    if ((isMiddleMouse || isRightMouse) && isFastTravel) {
      document.exitPointerLock()
      if (isRightMouse && mapSize?.length) {
        setIsAfterFastTravel(false)
        /** Позиция центра активного вью */
        contextMenuHandler(width * xFactor + width / 2, height * yFactor + height / 2)
      }
    } else if (!isFastTravel && isMiddleMouse) {
      map.getViewport().requestPointerLock()
    }
  }
  const onDragStart = useCallback(
    (evt: MouseEvent) => {
      if (evt.button !== 1 /** not middle-click */) return
      if (changeMode) map.getViewport().style.cursor = 'grabbing'
    },
    [changeMode],
  )
  const onDragEnd = useCallback(
    (evt: MouseEvent) => {
      if (evt.button !== 1 /** not middle-click */) return
      if (changeMode) map.getViewport().style.cursor = ''
    },
    [changeMode],
  )

  const changeZoomFromTool = useCallback(
    (fnId: string, zoom: number) => {
      if (fnId !== viewerId) return
      map.getView().setResolution(zoom)
      bus.$emit('afterMapZoom', viewerId)
    },
    [slideId, viewerId],
  )

  const changeRotationFromTool = useCallback(
    (fnId: string, degrees: number) => {
      if (fnId !== viewerId) return
      map.getView().setRotation(fromDegreesToRadians(degrees))
      bus.$emit('afterMapRotation', viewerId)
    },
    [slideId, viewerId],
  )

  const onPointerMove = (evt: MapBrowserEvent<PointerEvent>) => {
    const pixel = map.getEventPixel(evt.originalEvent)
    const hit = map.hasFeatureAtPixel(pixel)

    map.getViewport().style.cursor = hit ? '' : 'auto'

    if (drawMode) {
      map.getViewport().style.cursor = 'auto'
    }
  }

  useEffect(() => {
    const rendercompleteHandle = (e: RenderEvent) => {
      const view = (e.target as olMap).getView()
      updateUserActivityData(view, true)
    }
    map.once('rendercomplete', rendercompleteHandle)
    return () => {
      map.un('rendercomplete', rendercompleteHandle)
    }
  }, [slideId])

  useEffect(() => {
    !countingObjectType && map.on('pointermove', onPointerMove)

    const view = map.getView()
    bus.$addEventListener('tool:zoom', changeZoomFromTool)
    bus.$addEventListener('tool:rotate', changeRotationFromTool)
    view.on('change:center', handleChangeCenter)
    view.on('change:resolution', handleChangeZoom)
    view.on('change:rotation', handleChangeRotation)
    return () => {
      !countingObjectType && map.un('pointermove', onPointerMove)
      bus.$removeEventListener('tool:zoom', changeZoomFromTool)
      bus.$removeEventListener('tool:rotate', changeRotationFromTool)
      view.un('change:center', handleChangeCenter)
      view.un('change:resolution', handleChangeZoom)
      view.un('change:rotation', handleChangeRotation)
    }
  }, [viewingState, activeViewerId, slideId, caseId, config, drawMode, countingObjectType])

  useEffect(() => {
    document.addEventListener('mousedown', onDragStart)
    document.addEventListener('mouseup', onDragEnd)
    return () => {
      document.removeEventListener('mousedown', onDragStart)
      document.removeEventListener('mouseup', onDragEnd)
    }
  }, [changeMode])

  useEffect(() => {
    tileLayer?.on('prerender', onLayerPrerender)
    return () => {
      tileLayer?.un('prerender', onLayerPrerender)
    }
  }, [tileLayer])

  const updateUserActivityData = (
    /**
     * Инстанс вью
     */
    view: View,

    /**
     * Обновление при открытии слайда
     */
    openSlide = false,
  ) => {
    if (taskId !== undefined || atlasId !== undefined || isDefectModeViewer) return
    const slideQueryData: ISlide | undefined = queryClient.getQueryData([QUERY_TYPE.SLIDE, slideId])

    if (!openSlide && !isUpdatingSlideViewed && isUnviewedSlide(slideQueryData)) {
      queryClient.setQueryData([QUERY_TYPE.SLIDE, slideId], () => ({ ...slide, viewed: true }))
      throttledUpdateSlideViewedMutate(undefined, {
        onSuccess: () => viewerDispatch(viewerSlice.actions.setUnviewedSlides(unviewedSlides - 1)),
      })
    }

    const zoom: number = view.getResolution() || 0
    const center: number[] = view.getCenter() || [0, 0]
    const rotation: number = Math.round(view.getRotation() * (180 / Math.PI))

    if (zoom !== undefined && center !== undefined && rotation !== undefined) {
      publishUserActivity(viewerId, {
        centerX: center[0],
        centerY: center[1],
        rotation,
        slideId,
        zoom,
      })
    }
  }

  useEffect(() => {
    map.getViewport().addEventListener('mousedown', onPointerDown)
    return () => map.getViewport().removeEventListener('mousedown', onPointerDown)
  }, [drawMode, isFastTravel, activeViewerId, selectedAnnotationsIds.length, MITOSIS.isVisible, slideId])

  return (
    <>
      <MouseWheelZoomInteraction viewerId={activeViewerId} map={map} slide={slide} />
      {(handableCoreg || autoCoreg || activeViewerId === viewerId) && <FastTravelInteraction map={map} />}
    </>
  )
}

export default MapViewContainer
