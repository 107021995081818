import ActionConfirmationModal from 'features/reports/ui/ActionConfirmationModal'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { ReportFormData } from 'features/reports/ui/report-creation/right/ReportCreationFormRight'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

import ReportCreationFormLeftBodyContainer from './ReportCreationFormLeftBodyContainer'
import ReportCreationFormLeftFooterContainer from './ReportCreationFormLeftFooterContainer'
import ReportCreationFormLeftHeader from './ReportCreationFormLeftHeader'

interface Props {
  /**
   * Колбек отмены формирования заключения
   */
  onCancel: () => void
  /**
   * Сохранение заключения
   * @param payload
   */
  onSave: (payload: ReportFormData) => Promise<void>
  /**
   * Функция, вызываемая для удаления заключения.
   */
  onDelete: (reportId: number) => void
  /**
   * Состояние сохранения заключения
   */
  isSaving: boolean
  /**
   * Флаг, указывающий, что заключение в данный момент удаляется.
   */
  isDeleting: boolean
  /**
   * ID кейса
   */
  caseId: number
  /**
   * Функция, вызываемая при закрытии модального окна.
   */
  onCloseModal: () => void
}

const ReportCreationFormLeftContainer: FC<Props> = ({
  caseId,
  isDeleting,
  isSaving,
  onCancel,
  onCloseModal,
  onDelete,
  onSave,
}) => {
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { form, hasValidateErrors, isActionModalVisible, setIsActionModalVisible } = useReportCreationContext()
  const { isLisMode } = useSettingsAndUserRoles()

  const caseRecord: ICase | undefined = queryClient.getQueryData([QUERY_TYPE.CASE, caseId])
  /**
   * Обрабатывает событие подписания формы.
   * Устанавливает значение поля `signed` в `true`, валидирует поля формы
   * и вызывает сохранение данных.
   *
   * @async
   * @function
   * @returns {Promise<void>} Возвращает промис, который разрешается после завершения операции.
   */
  const onSign = async (): Promise<void> => {
    setIsSigning(true)

    try {
      await form.validateFields()

      const fields = form.getFieldsValue()
      fields.signed = true
      fields.locked = false

      await onSave(fields)
    } finally {
      setIsSigning(false)
    }
  }

  /**
   * Сохраняет данные формы.
   * Вызывает переданный обработчик сохранения с текущими значениями полей формы.
   *
   * @async
   * @function
   * @returns {Promise<void>} Возвращает промис, который разрешается после завершения операции.
   */
  const onSaveClick = async (): Promise<void> => {
    await onSave(form.getFieldsValue())
  }

  const onSaveDraft = async () => {
    setIsSigning(false)

    await form.validateFields()
    await onSave(form.getFieldsValue())
  }

  const caseName = caseRecord?.name ?? t('Не указано')

  return (
    <Wrapper vertical justify="space-between">
      <ReportCreationFormLeftHeader title={caseName} onCancel={onCancel} />
      <ReportCreationFormLeftBodyContainer isSigning={isSigning} />
      <ReportCreationFormLeftFooterContainer
        onSaveClick={onSaveClick}
        onDelete={onDelete}
        onCancel={onCancel}
        isSaving={isSaving}
        isDeleting={isDeleting}
        onSign={onSign}
        onSaveDraft={onSaveDraft}
        isSigning={isSigning}
      />
      {isActionModalVisible && (
        <ActionConfirmationModal
          hasErrors={hasValidateErrors}
          onCancel={() => setIsActionModalVisible(false)}
          onDiscard={onCloseModal}
          isSaving={isSaving}
          isLisMode={isLisMode}
          onSave={!isLisMode ? onSaveClick : onSaveDraft}
        />
      )}
    </Wrapper>
  )
}

export default ReportCreationFormLeftContainer

const Wrapper = styled(Flex)`
  width: 409px;
  border-right: 1px solid var(--color-border-1);
`
