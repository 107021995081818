import { Divider } from 'antd'
import { InputNumberProps } from 'antd/es/input-number'
import { useTypedSelector } from 'app/redux/lib/selector'
import { StainSelectContainer } from 'entities/stains'
import { ISlideAdditional, ModalWrapper, useAdditionalServiceMutation } from 'features/additional-order'
import { GroupElems, Label, LabelTitle } from 'features/additional-order/ui/ModalWrapper/ModalWrapper.styles'
import { useResearchRequestDefaultStain } from 'features/workspace/model/workspacesSlice'
import { viewerPageSlice } from 'pages/viewer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputNumberElement } from 'shared/ui/kit'
import { DictionaryItem } from 'types/IDictionary'
import ISlide from 'types/ISlide'
import { useViewerDispatch } from 'viewer/container'

import { DEFAULT_PAYLOAD } from './constants'
import { BlockText } from './NewSerialCuts.styles'
import { INewSerialCutsProps } from './NewSerialCuts.types'

export const NewSerialCuts = ({ caseId, slide, viewerId }: INewSerialCutsProps) => {
  const { caseReferencesBlocks, caseReferencesSlides } = useTypedSelector((state) => state.viewerPage)
  const [currentSlides, setCurrentSlides] = useState<ISlideAdditional[]>([])
  const [valueAmount, setValueAmount] = useState('1')
  const [valueComment, setValueComment] = useState('')
  const [currentStain, setCurrentStain] = useState<DictionaryItem>()
  const [currentSlide, setCurrentSlide] = useState<ISlide>()

  const refModal = useRef<any>()
  const { t } = useTranslation()
  const { isNewSerialCuts } = useTypedSelector((state) => state.viewerPage)
  const viewerDispatch = useViewerDispatch(viewerId)
  const defaultStain = useResearchRequestDefaultStain()

  const { error, isError, isLoading, isSuccess, mutate: setPayload } = useAdditionalServiceMutation(caseId)

  const caseSlideReference = caseReferencesSlides?.find(
    (refSlide) => refSlide?.slideExternalId === currentSlide?.barcode,
  )

  const caseBlockReferenceId = caseReferencesBlocks?.find(
    (refBlock) => refBlock?.blockExternalId === caseSlideReference?.blockExternalId,
  )?.caseBlockReferenceId

  const text = caseSlideReference?.blockExternalId

  const updateSlide = async (stain?: DictionaryItem) => {
    setCurrentStain(stain)
  }

  const onClose = () => {
    viewerDispatch(viewerPageSlice.actions.setNewSerialCutshotModal(false))
  }

  const handleSubmit = useCallback(() => {
    const payload = {
      slides: currentSlides,
      ...(valueComment ? { preparationComment: valueComment } : ''),
      ...DEFAULT_PAYLOAD,
    }
    setPayload(payload)
  }, [currentSlides, valueComment])

  const handleAmount = useCallback((value: InputNumberProps['value']) => {
    if (value) {
      setValueAmount(String(value))
    }
  }, [])

  useEffect(() => {
    isNewSerialCuts ? refModal.current.open() : refModal.current.close()
  }, [isNewSerialCuts])

  useEffect(() => {
    const slides = Array.from({ length: Number(valueAmount) }, () => ({
      caseBlockReferenceId,
      caseSlideReferenceId: caseSlideReference?.caseSlideReferenceId,
      stain: currentStain,
    }))

    setCurrentSlides(slides)
  }, [currentStain, valueAmount])

  useEffect(() => {
    if (isSuccess) {
      viewerDispatch(viewerPageSlice.actions.resetSelectedSlidesState())
      onClose()
    }
  }, [isSuccess])

  useEffect(() => {
    if (slide?.stain) {
      setCurrentStain(slide?.stain)
    }
    if (slide) {
      setCurrentSlide(slide)
    }
  }, [])

  return (
    <ModalWrapper
      title={t('Последовательные срезы')}
      refModal={refModal}
      error={error}
      handleSubmit={handleSubmit}
      isDisabled={!currentStain || isLoading}
      handleComment={(e) => setValueComment(e.target.value)}
      valueComment={valueComment}
      isError={isError}
      onClose={onClose}
    >
      <Divider />
      <BlockText>
        {t('Блок')}&nbsp;{text}
      </BlockText>
      <GroupElems>
        <Label>
          <LabelTitle>{t('Кол-во стекол')}</LabelTitle>
          <InputNumberElement min={1} max={99} defaultValue={1} value={valueAmount} onChange={handleAmount} />
        </Label>

        <Label>
          <LabelTitle>{t('Окраска')}</LabelTitle>
          <StainSelectContainer
            initialStane={defaultStain}
            placeholder={t('Поиск по окраскам')}
            onChange={updateSlide}
            value={currentStain}
          />
        </Label>
      </GroupElems>
    </ModalWrapper>
  )
}
