import { Divider } from 'antd'
import { getDataFromDB } from 'app/indexedDB'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useLiveQuery } from 'dexie-react-hooks'
import { ModalWrapper, RESEARCH_REQUEST_TYPE, useAdditionalServiceMutation } from 'features/additional-order'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { viewerPageSlice } from 'pages/viewer'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import useOutsideClick from 'shared/lib/hooks/useOutsideClick'
import { SmallIconButtonElement } from 'shared/ui/kit'
import { ResearchRequestReasonCode, TDefectTypeDictionaryItem } from 'types/IDictionary'
import ISlide, { SlideDefectType } from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'
import { useViewerDispatch } from 'viewer/container'

import { ICommonProps, ISlideAdditional } from './Common.types'
import {
  ButtonAdd,
  Dot,
  DropDown,
  SlideContainer,
  SlideText,
  SlideTextDropdown,
  SlideTextInner,
} from './CommonModal.styles'

/** Используется для модалок Перерезки и Пересканирования */
export const CommonModal = ({ caseId, slide, type, viewerId }: ICommonProps) => {
  const refModal = useRef<any>()
  const { t } = useTranslation()
  const { caseReferencesBlocks, caseReferencesSlides, selectedSlides } = useTypedSelector((state) => state.viewerPage)
  const queryClient = useQueryClient()
  const { slideId } = useViewerIdSlideState(viewerId)

  const [valueComment, setValueComment] = useState('')
  const [valueDefectComment, setValueDefectComment] = useState('')
  const [selectedDefectType, setSelectedDefectType] = useState<TDefectTypeDictionaryItem | null>(null)
  const [isDropDownOpen, setDropDownOpen] = useState(false)
  const [isRemoveVisible, setRemoveVisible] = useState(false)
  const [currentSlides, setCurrentSlides] = useState<ISlideAdditional[]>([])
  const [restSlides, setRestSlides] = useState<ISlideAdditional[]>([])
  const viewerDispatch = useViewerDispatch(viewerId)
  const { isNewCuts, isRescan } = useTypedSelector((state) => state.viewerPage)
  const dictionaryResearchRequestReason = useLiveQuery(
    () => getDataFromDB(EDictionaryNS.RESEARCH_REQUEST_REASON),
    [],
  ) as TDict
  const { data: researchRequestReasonData = [] } = { ...dictionaryResearchRequestReason }

  const caseRecord = queryClient.getQueryData<ISlideGrouped[]>([QUERY_TYPE.SLIDE_GROUPED, caseId])
  const slides = caseRecord?.flatMap((item) => item.slides)

  const { error, isError, isLoading, isSuccess, mutate: setPayload } = useAdditionalServiceMutation(caseId)

  const ref = useOutsideClick(() => setDropDownOpen(false))

  const onClose = () => {
    if (type === RESEARCH_REQUEST_TYPE.NEW_CUTS) {
      viewerDispatch(viewerPageSlice.actions.setNewCutsModal(false))
    }
    if (type === RESEARCH_REQUEST_TYPE.RESCAN) {
      viewerDispatch(viewerPageSlice.actions.setRescanModal(false))
    }
  }

  const handleButtonClick = () => setDropDownOpen(!isDropDownOpen)

  const handleSlideClick = (slide: ISlideAdditional) => {
    setCurrentSlides((prevSlides) => {
      if (!prevSlides.some((s) => s.caseSlideExternalId === slide.caseSlideExternalId)) {
        return [...prevSlides, slide]
      }
      return prevSlides
    })
    const newSlides = restSlides.filter((s) => s.caseSlideExternalId !== slide.caseSlideExternalId)
    setRestSlides(newSlides)
    setDropDownOpen(false)
  }

  const defectReasonData = (researchRequestReasonData as TDefectTypeDictionaryItem[]).find(
    (item) => item.code === ResearchRequestReasonCode.DEFECT,
  )
  const diagReasonData = (researchRequestReasonData as TDefectTypeDictionaryItem[]).find(
    (item) => item.code === ResearchRequestReasonCode.DIAG,
  )

  const defectReason = defectReasonData && { code: defectReasonData.code, id: defectReasonData.id }
  const diagReason = diagReasonData && { code: diagReasonData.code, id: diagReasonData.id }

  const defectTypes = selectedDefectType ? [{ ...selectedDefectType }] : []
  const researchRequestReason = defectTypes.length === 0 ? diagReason : defectReason

  const handleSubmit = useCallback(() => {
    const payloadArray = currentSlides.map((slide) => {
      const barcode = slides?.find((item) => slide.caseSlideExternalId === item.barcode)?.barcode
      const slideRererenceId = caseReferencesSlides?.find(
        (item) => item.slideExternalId === barcode,
      )?.caseSlideReferenceId

      return {
        caseBlockReferenceId: slide.caseBlockReferenceId,
        caseSlideReferenceId: slideRererenceId,
        stain: slide.stain,
      }
    })

    const payload = {
      slides: payloadArray,
      ...(valueComment ? { preparationComment: valueComment } : ''),
      ...(valueDefectComment ? { qualityControlComment: valueDefectComment } : ''),
      defectTypes,
      newCaseRequested: false,
      researchRequestReason,
      ...(type === RESEARCH_REQUEST_TYPE.RESCAN
        ? { type: RESEARCH_REQUEST_TYPE.RESCAN }
        : { type: RESEARCH_REQUEST_TYPE.NEW_CUTS }),
    }
    setPayload(payload, {
      onSuccess: () => {
        queryClient.setQueryData<ISlide | undefined>([QUERY_TYPE.SLIDE, slideId], (oldData) => {
          if (!oldData) return undefined

          return {
            ...oldData,
            slideDefect: SlideDefectType.DEFECT,
          }
        })
        queryClient.invalidateQueries([QUERY_TYPE.SLIDE_GROUPED, caseId])
      },
    })
  }, [currentSlides, valueComment, valueDefectComment, selectedDefectType])

  const handleMouseEnter = () => setRemoveVisible(true)
  const handleMouseLeave = () => setRemoveVisible(false)
  const handleRemove = useCallback(
    (id?: string) => {
      const newSlides = currentSlides.filter((slide) => slide.caseSlideExternalId !== id)
      setCurrentSlides(newSlides)
      const removedSlide = currentSlides.find((slide) => slide.caseSlideExternalId === id)
      if (removedSlide) {
        setRestSlides([...restSlides, removedSlide])
      }
    },
    [currentSlides],
  )

  useEffect(() => {
    if (type === RESEARCH_REQUEST_TYPE.NEW_CUTS) {
      isNewCuts ? refModal.current.open() : refModal.current.close()
    }

    if (type === RESEARCH_REQUEST_TYPE.RESCAN) {
      isRescan ? refModal.current.open() : refModal.current.close()
    }
  }, [isRescan, isNewCuts])

  useEffect(() => {
    if (isSuccess) {
      viewerDispatch(viewerPageSlice.actions.resetSelectedSlidesState())
      onClose()
    }
  }, [isSuccess])

  useEffect(() => {
    const getSlides = (isChecked: boolean) =>
      selectedSlides
        ?.filter((slide) => slide.isChecked === isChecked)
        .map((slide) => {
          const caseSlide = caseReferencesSlides?.find((ref) => ref?.caseSlideReferenceId === slide?.slideRererenceId)
          const caseBlockReferenceId = caseReferencesBlocks?.find(
            (refBlock) => refBlock?.blockExternalId === caseSlide?.blockExternalId,
          )?.caseBlockReferenceId

          if (caseBlockReferenceId && caseSlide?.slideExternalId && caseSlide?.stain) {
            return {
              caseBlockReferenceId,
              caseSlideExternalId: caseSlide?.slideExternalId,
              stain: caseSlide?.stain,
            }
          }
        })
        .filter(Boolean) as ISlideAdditional[]

    const currentSlides = getSlides(true)

    if (Array.isArray(currentSlides) && currentSlides?.length) {
      setCurrentSlides(currentSlides)
    } else if (slide) {
      const caseSlide = caseReferencesSlides?.find((ref) => ref?.slideExternalId === slide?.barcode)
      const caseBlockReferenceId = caseReferencesBlocks?.find(
        (refBlock) => refBlock?.blockExternalId === caseSlide?.blockExternalId,
      )?.caseBlockReferenceId
      const newSlide = {
        caseBlockReferenceId,
        caseSlideExternalId: caseSlide?.slideExternalId,
        stain: caseSlide?.stain,
      }
      setCurrentSlides([newSlide])
    }

    const restSlides = slides
      ?.map((item) => {
        const caseSlide = caseReferencesSlides?.find((ref) => ref?.slideExternalId === item.barcode)
        const caseBlockReferenceId = caseReferencesBlocks?.find(
          (refBlock) => refBlock?.blockExternalId === caseSlide?.blockExternalId,
        )?.caseBlockReferenceId
        if (caseBlockReferenceId && caseSlide?.slideExternalId && caseSlide?.stain) {
          return {
            caseBlockReferenceId,
            caseSlideExternalId: caseSlide?.slideExternalId,
            stain: caseSlide?.stain,
          }
        }
      })
      .filter(
        (item) => !currentSlides.some((currentSlide) => currentSlide.caseSlideExternalId === item?.caseSlideExternalId),
      )
      .filter(Boolean)

    const newRestSlides = restSlides?.filter(
      (slideRest) => slideRest?.caseSlideExternalId !== slide?.barcode,
    ) as ISlideAdditional[]
    if (Array.isArray(newRestSlides) && newRestSlides?.length) {
      setRestSlides(newRestSlides)
    }
  }, [])

  return (
    <ModalWrapper
      title={type === RESEARCH_REQUEST_TYPE.NEW_CUTS ? t('Перерезка') : t('Пересканирование')}
      refModal={refModal}
      error={error}
      handleSubmit={handleSubmit}
      isDisabled={isLoading || !currentSlides?.length}
      handleComment={(e) => setValueComment(e.target.value)}
      valueComment={valueComment}
      valueDefectComment={valueDefectComment}
      handleDefectComment={(e) => setValueDefectComment(e.target.value)}
      isError={isError}
      onClose={onClose}
      onDefectChange={setSelectedDefectType}
    >
      <Divider />
      <SlideContainer>
        {currentSlides.map((slide) => (
          <SlideText key={slide?.caseSlideExternalId} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <SlideTextInner>
              {slide?.caseSlideExternalId} <Dot /> {slide?.stain?.shortName}
            </SlideTextInner>

            {isRemoveVisible && (
              <SmallIconButtonElement
                icon="delete"
                title={t('Удалить')}
                placement="left"
                onClick={() => handleRemove(slide?.caseSlideExternalId)}
              />
            )}
          </SlideText>
        ))}
      </SlideContainer>

      <ButtonAdd
        onClick={handleButtonClick}
        style={{ pointerEvents: !restSlides?.length || isDropDownOpen ? 'none' : 'auto' }}
      >
        {t('Добавить стекло')}
      </ButtonAdd>

      {Boolean(isDropDownOpen) && !!restSlides?.length && (
        <DropDown ref={ref}>
          {restSlides.map((slide: ISlideAdditional) => (
            <SlideTextDropdown onClick={() => handleSlideClick(slide)} key={slide?.caseSlideExternalId}>
              <SlideTextInner>
                {slide?.caseSlideExternalId} <Dot /> {slide?.stain?.shortName}
              </SlideTextInner>
            </SlideTextDropdown>
          ))}
        </DropDown>
      )}
    </ModalWrapper>
  )
}
