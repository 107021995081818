import { useTypedSelector } from 'app/redux/lib/selector'
import { push, replace } from 'connected-react-router'
import { useCaseQuery } from 'features/cases/api/query'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectUrlCaseId, selectUrlSlideId, viewerPageSlice } from 'pages/viewer'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { ISlideGrouped } from 'types/ISlideGrouped'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'

export const useViewerRoute = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const urlCaseId = useSelector(selectUrlCaseId)
  const urlSlideId = useSelector(selectUrlSlideId)
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { firstViewerId } = useOpenViewers()
  const { changeViewerSlide } = useViewerPageProvided()
  const routerState = useTypedSelector((state) => state.router.location.state)
  const workspaceId = useCurrentWorkspaceId()
  const { source } = useViewerIdSlideState(viewerId)
  const { isError, isLoading: isCaseLoading } = useCaseQuery({ caseId: urlCaseId, source })
  const slideGrouped = queryClient.getQueryData<ISlideGrouped[]>([QUERY_TYPE.SLIDE_GROUPED, urlCaseId])
  const { selectedBbox } = useViewerMainSelector(firstViewerId)
  const flatSlides = slideGrouped?.flatMap((item) => item.slides.map((slide) => slide)) || []
  const isCorrectSlide = !!flatSlides.find((s) => s.slideId === urlSlideId)
  const isNoSlideId = !urlSlideId

  /**Select first slide if no/wrong slideId url param */
  useEffect(() => {
    if (urlCaseId && (isNoSlideId || !isCorrectSlide)) {
      const firstSlideData = slideGrouped?.[0]?.slides[0]
      const firstSlideId = firstSlideData?.slideId || 0
      const correctSlideId = flatSlides.find((slide) => slide.slideId === firstSlideId)
      const firstGroupType = firstSlideData?.groupType
      if (!correctSlideId || !firstGroupType) return

      dispatch(replace(`/workspace/${workspaceId}/viewer/${urlCaseId}?slideId=${firstSlideId}`, routerState))

      changeViewerSlide(firstViewerId, {
        caseId: urlCaseId,
        slideGroupType: firstGroupType,
        slideId: firstSlideId,
        source: 'PLATFORM',
        viewerMode: 'DEFAULT',
      })
    }
  }, [slideGrouped, urlCaseId, isNoSlideId, urlCaseId, isCorrectSlide, workspaceId])

  const goToCasesList = useCallback(() => dispatch(push(`/workspace/${workspaceId}/cases?rel=owner`)), [])
  const goToCase = useCallback(
    () => dispatch(push(`/workspace/${workspaceId}/cases?rel=shared&caseId=${urlCaseId}`)),
    [],
  )

  const isWrongCase = isError
  const isNoSlide = false

  useEffect(
    () => () => {
      /** Clear selected bbox*/
      !!selectedBbox && viewerDispatch(viewerSlice.actions.setSelectedBbox())
      dispatch(viewerPageSlice.actions.resetAllViewersStateExceptArtefacts())
    },
    [],
  )

  return { goToCase, goToCasesList, isCaseLoading, isNoSlide, isWrongCase }
}
