import { useAnnotationsQuery } from 'features/annotations/api'
import { useSlidesDefects } from 'features/thumbnails/hooks/useSlidesDefects'
import { selectDefectsViewerUrlCaseId, selectUrlSlideId } from 'pages/viewer/model/viewerPageSlice'
import CommonViewerRouteLayout from 'pages/viewer/ui/common/CommonViewerRouteLayout'
import ViewerContextProvider from 'pages/viewer/ui/ViewerContext'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { IAnnotation } from 'types/IAnnotations'

import DefectsViewerContainer from './DefectsViewerContainer'

export const DefectsViewerRoute = () => {
  const queryClient = useQueryClient()
  const { slides, slidesIds: defectsSlidesIds } = useSlidesDefects()
  const urlDefectCaseId = useSelector(selectDefectsViewerUrlCaseId) as number
  const urlSlideId = useSelector(selectUrlSlideId) as number

  const { data: annotationBySlide } = useAnnotationsQuery(urlDefectCaseId, urlSlideId)
  const annotationsBySlide = (annotationBySlide?.ids || [])?.map((id) =>
    queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]),
  ) as IAnnotation[]

  return (
    <ViewerContextProvider>
      <CommonViewerRouteLayout
        annotationsByCase={[{ annotations: annotationsBySlide, slideId: urlSlideId }]}
        isDefectModeViewer
        slidesIds={defectsSlidesIds}
        slides={slides}
      >
        <DefectsViewerContainer />
      </CommonViewerRouteLayout>
    </ViewerContextProvider>
  )
}
