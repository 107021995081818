import { Divider } from 'antd'
import { useCasesCountQuery } from 'features/cases-management/api/query'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { CasesTableFilter } from 'features/cases-management/ui/filters/CasesTableFIlter'
import AdaptiveThumbnailContainer from 'features/thumbnails/AdaptiveThumbnailContainer'
import { useSlidesDefects } from 'features/thumbnails/hooks/useSlidesDefects'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectDefectsTabUrl, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { memo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useInfiniteScroll } from 'shared/ui/infinite-scroll/useInfiniteScroll'
import { IconElement, SpinElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { TCasesManagementTabs } from 'types/TTab'
import { useViewerDispatch } from 'viewer/container'
import { ML_TOOLS_MAP } from 'viewer/tools/ui/modal/MlToolsMap'

const DefectsSlidesListContainer = memo(() => {
  const { t } = useTranslation()
  const { activeViewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(activeViewerId)
  const { fetchMoreSlides, isLastPage, isLoadingDefects, slides } = useSlidesDefects()
  const tabUrl = useSelector(selectDefectsTabUrl) as TCasesManagementTabs
  const { data } = useCasesCountQuery(ECaseTableType.DEFECTS)
  const anchorRef = useRef(null)
  const slideSummary = tabUrl && data?.[tabUrl]
  const isAnchorHidden = useInfiniteScroll({
    anchor: anchorRef,
    fetchMore: fetchMoreSlides,
    isLastPage,
    itemsLength: slides.length,
  })

  useEffect(() => {
    viewerDispatch(viewerPageSlice.actions.setTool({ tool: ML_TOOLS_MAP['ARTEFACTS'].id, value: true }))
  }, [])

  return (
    <Container>
      <HeaderContainer>
        <TooltipElement placement="topLeft" title={t('Загруженные микропрепараты')}>
          <CountGroup>
            <StyledIcon isAllSlides={true} size={'md'} name="imagesSmall" />
            {!!slideSummary && <TitleCount>{slideSummary}</TitleCount>}
          </CountGroup>
        </TooltipElement>
        <CasesTableFilter />
      </HeaderContainer>
      <Divider />
      <Content ref={anchorRef}>
        {slides?.map(({ caseId, slideId, ...rest }) => (
          <AdaptiveThumbnailContainer
            slide={{ caseId, slideId, ...rest }}
            slideId={slideId}
            caseId={caseId}
            key={slideId}
          />
        ))}

        {(!isAnchorHidden || isLoadingDefects) && (
          <SpinContainer ref={anchorRef}>
            <SpinElement />
          </SpinContainer>
        )}
      </Content>
    </Container>
  )
})

export default DefectsSlidesListContainer

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-bg-2);
  padding-right: 8px;
  z-index: 10;
`

const CountGroup = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 4px 8px 0 8px;
`

const TitleCount = styled(TitleElement)`
  font-size: 12px !important;
  line-height: 16px !important;
`

const StyledIcon = styled(IconElement)<{ isAllSlides?: boolean }>`
  color: ${({ isAllSlides }) => (isAllSlides ? 'var(--Light-Text-text-3, #595b5d)' : 'var(--color-red)')};
`

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
