import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import { ISlideAnnotation } from 'types/IAnnotations'
import ISlide from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'

type TViewerContext = {
  /** Массив слайдов */
  slides: ISlide[]
  /** Массив объектов слайдов, сгруппированных по блокам */
  slideGrouped: ISlideGrouped[]
  /** Массив идентификаторов слайдов */
  slidesIds: number[]
  /** Список файлов в виде строк */
  filesList: string[]
  /** Флаг состояния загрузки */
  isLoading: boolean
  /** Флаг режима просмотра дефектов */
  isDefectModeViewer: boolean
  /** Функция для установки состояния загрузки */
  setLoading: Dispatch<SetStateAction<boolean>>
  /** Функция для переключения режима просмотра дефектов */
  setDefectModeViewer: Dispatch<SetStateAction<boolean>>
  /** Функция для обновления массива слайдов */
  setSlides: Dispatch<SetStateAction<ISlide[]>>
  /** Функция для обновления сгруппированных слайдов */
  setSlideGrouped: Dispatch<SetStateAction<ISlideGrouped[]>>
  /** Функция для обновления массива идентификаторов слайдов */
  setSlidesIds: Dispatch<SetStateAction<number[]>>
  /** Функция для обновления списка файлов */
  setFilesList: Dispatch<SetStateAction<string[]>>
  /** annotations - аннотаци для текущего кейса/кейсов */
  annotationsByCase: ISlideAnnotation[] | undefined
  /** Функция для обновления списка аннотаций */
  setAnnotationsByCase: Dispatch<SetStateAction<ISlideAnnotation[] | undefined>>
}

const initialViewerContextState: TViewerContext = {
  annotationsByCase: undefined,
  filesList: [],
  isDefectModeViewer: false,
  isLoading: false,
  setAnnotationsByCase: () => {},
  setDefectModeViewer: () => {},
  setFilesList: () => {},
  setLoading: () => {},
  setSlideGrouped: () => {},
  setSlides: () => {},
  setSlidesIds: () => {},
  slideGrouped: [],
  slides: [],
  slidesIds: [],
}

const ViewerContext = createContext<TViewerContext>(initialViewerContextState)

export const useViewerContext = () => useContext(ViewerContext)

const ViewerContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [slides, setSlides] = useState<ISlide[]>([])
  const [annotationsByCase, setAnnotationsByCase] = useState<ISlideAnnotation[]>()
  const [slidesIds, setSlidesIds] = useState<number[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [filesList, setFilesList] = useState<string[]>([])
  const [slideGrouped, setSlideGrouped] = useState<ISlideGrouped[]>([])
  const [isDefectModeViewer, setDefectModeViewer] = useState<boolean>(false)

  return (
    <ViewerContext.Provider
      value={{
        annotationsByCase,
        filesList,
        isDefectModeViewer,
        isLoading,
        setAnnotationsByCase,
        setDefectModeViewer,
        setFilesList,
        setLoading,
        setSlideGrouped,
        setSlides,
        setSlidesIds,
        slideGrouped,
        slides,
        slidesIds,
      }}
    >
      {children}
    </ViewerContext.Provider>
  )
}

export default ViewerContextProvider
