import { RESEARCH_REQUEST_TYPE, STATUS_ADDITIONAL_ORDER } from 'features/additional-order'

export const STATUSES = {
  [STATUS_ADDITIONAL_ORDER.CREATION_PENDING]: 'Создан',
  [STATUS_ADDITIONAL_ORDER.CREATED]: 'Создан',
  [STATUS_ADDITIONAL_ORDER.IN_PROGRESS]: 'В работе',
  [STATUS_ADDITIONAL_ORDER.READY]: 'Готов',
  [STATUS_ADDITIONAL_ORDER.CANCELLED]: 'Отменён',
  [STATUS_ADDITIONAL_ORDER.FAILED]: 'Ошибка',
}

export const ADDITIONAL_ORDER = {
  [RESEARCH_REQUEST_TYPE.RESCAN]: 'Пересканирование',
  [RESEARCH_REQUEST_TYPE.NEW_CUTS]: 'Перерезка',
  [RESEARCH_REQUEST_TYPE.NEW_SERIAL_CUTS]: 'Последовательные срезы',
  [RESEARCH_REQUEST_TYPE.NEW_STAIN]: 'Новая окраска',
  [RESEARCH_REQUEST_TYPE.NEW_STAIN_HC]: 'Гистохимическая окраска',
  [RESEARCH_REQUEST_TYPE.NEW_STAIN_IHC]: 'ИГХ-окраска',
  [RESEARCH_REQUEST_TYPE.NEW_STAIN_IHC_NEW_CASE]: 'ИГХ-окраска (новым случаем)',
}
