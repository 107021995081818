import { useCaseSlideGrouped } from 'features/cases/api/query'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { StompClientContext, WsResponseCase } from 'processes/stomp'
import { trackUploadService, TusContext } from 'processes/tus'
import { ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import ICase, { ICaseRelation } from 'types/ICase'
import ISlide from 'types/ISlide'
import ITrackUploadFile from 'types/ITrackUploadFile'
import { v4 as uuidv4 } from 'uuid'

import SlideUploadButton from './ui/SlideUploadButton'

const ACCEPT = '.svs, .tif, .vmu, .ndpi, .scn, .zip, .tiff, .svslide, .bif, .jpg, .jpeg, .tar, .png'

type Props = {
  caseId: number
  slides: ISlide[]
  buttonType?: 'icon' | 'primary' | 'default'
  disabled?: boolean
  slideList?: boolean
  children?: ReactNode
}

const SlideUploadButtonContainer = ({ buttonType = 'icon', caseId, children, disabled, slideList, slides }: Props) => {
  const [isWatched, setWatched] = useState(false)
  const { groupId, pushFileToQueue } = useContext(TusContext)
  const { t } = useTranslation()
  const processFileList = useCallback(
    async (event: any) => {
      const userAgent = window.navigator.userAgent

      const items: { file: File; data: ITrackUploadFile }[] = []

      for (const file of event.target.files) {
        try {
          if (file?.name) {
            const fileTypes = ACCEPT.split(', ')
            const ext = file.name.match(/\.[0-9a-z]+$/i)[0]
            if (!fileTypes.find((type) => type.toLowerCase() === ext.toLowerCase())) {
              return notices.error({
                message: t('Данный формат не поддерживается'),
              })
            }
          }
          items.push({
            data: {
              attemptNumber: 1,
              fileName: file.name,
              fileSize: file.size,
              fileUuid: uuidv4(),
              isAlreadyPartiallyUploaded: false,
            },
            file,
          })
        } catch (e) {
          notices.error({
            message: t('Не удалось загрузить файл'),
          })
          console.log(e)
        }
      }
      trackUploadService.createFileGroup({
        caseId,
        files: items.map((item) => item.data),
        uploadId: groupId,
        userAgent,
      })
      items.forEach((item) => {
        pushFileToQueue({ caseId, file: item.file, fileId: item.data.fileUuid! })
      })
      if (items.length) {
        setWatched(true)
      }
    },
    [caseId],
  )

  return (
    <>
      <SlideUploadButton
        processFileList={processFileList}
        buttonType={buttonType}
        accept={ACCEPT}
        disabled={disabled}
        slideList={slideList}
      >
        {children}
      </SlideUploadButton>
      {isWatched && <CaseSubscription slides={slides} caseId={caseId} />}
    </>
  )
}

function CaseSubscription({ caseId, slides }: { caseId: number; slides: ISlide[] }) {
  const queryClient = useQueryClient()
  const { subscribe } = useContext(StompClientContext)
  const { refetch: refetchCaseGrouped } = useCaseSlideGrouped(caseId)
  const workspaceId = useCurrentWorkspaceId()

  subscribe<WsResponseCase>(
    '/queue/case/' + caseId,
    ({ payload }) => {
      queryClient.setQueryData<ICase>([QUERY_TYPE.CASE, caseId], (prevRecord) => ({
        ...payload,
        patient: prevRecord?.patient || payload.patient,
        relation: ICaseRelation.OWNER,
      }))
      slides.forEach((slide) => {
        queryClient.setQueryData([QUERY_TYPE.SLIDE, slide.slideId], { ...slide, caseId })
      })
      refetchCaseGrouped()
    },
    workspaceId ? { 'x-oc-workspace-id': String(workspaceId) } : undefined,
  )
  return null
}

export default SlideUploadButtonContainer
