import { SelectProps } from 'antd/es/select'
import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TAnnotationData } from 'features/annotations/api/query'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IAnnotation } from 'types/IAnnotations'
import { TDefectTypeDictionaryItem } from 'types/IDictionary'
import { updateAnnotationDescription } from 'viewer/map/layers/annotations/lib/helpers'

import { StyledCheckboxElement } from './AnnotationDescriptionAndAreaControl'

type TProps = {
  /** Аннотация */
  annotation: IAnnotation
  /** Коллбек для изменения информации об аннотации */
  editAnnotation: (annotation: TAnnotationData) => void
  /** Может ли пользователь редактировать аннотацию */
  canUserEditAnnotation?: boolean
}

/** Названия текста для подписи дефекта */
const DEFECT_TEXT = 'Дефект'

const DefectSelectContainer: FC<TProps> = ({ annotation, canUserEditAnnotation = true, editAnnotation }) => {
  const [isDefect, setIsDefect] = useState<boolean>(!!annotation.defect)
  const [selectedDefectType, setSelectedDefectType] = useState<TDefectTypeDictionaryItem | null>(
    annotation.defectType || null,
  )
  const { t } = useTranslation()
  const dictionary = useLiveQuery(() => getDataFromDB(EDictionaryNS.DEFECT_TYPE)) as TDict<TDefectTypeDictionaryItem[]>
  const { data: defectTypes } = { ...dictionary }

  const toggleDefect = () => {
    setIsDefect((prev) => {
      const newIsDefect = !prev
      const updatedAnnotationData: TAnnotationData = { ...annotation, defect: newIsDefect }

      if (!newIsDefect && selectedDefectType) {
        updatedAnnotationData.defectType = null
        setSelectedDefectType(null)
      }

      if (newIsDefect && !annotation.caption) {
        updateAnnotationDescription(updatedAnnotationData, DEFECT_TEXT)
      }

      editAnnotation(updatedAnnotationData)

      return newIsDefect
    })
  }

  /**
   * Обработчик выбора типа дефекта для аннотации.
   *
   * @param {number} selectedDefectTypeId - Идентификатор выбранного типа дефекта.
   */
  const onSelectDefectType: SelectProps['onSelect'] = (selectedDefectTypeId: number) => {
    const newSelectedDefectType = defectTypes.find((defectType) => selectedDefectTypeId === defectType.id) || null

    setSelectedDefectType(newSelectedDefectType)

    const updatedAnnotation: TAnnotationData = { ...annotation, defectType: newSelectedDefectType }

    // Обновляем описание, если выбран дефект
    if (newSelectedDefectType && (annotation.caption === DEFECT_TEXT || annotation.defect)) {
      updateAnnotationDescription(updatedAnnotation, `${DEFECT_TEXT}: ${newSelectedDefectType.name}`)
    }

    editAnnotation(updatedAnnotation)
  }

  return (
    <DefectWrapper>
      <StyledCheckboxElement disabled={!canUserEditAnnotation} checked={isDefect} onChange={toggleDefect}>
        {t('Дефект')}
      </StyledCheckboxElement>
      {isDefect && (
        <SelectElement
          onClick={(e) => e.stopPropagation()}
          placeholder={t('Выберите тип дефекта')}
          options={defectTypes?.map(({ id, name }) => ({ label: name, value: id }))}
          value={selectedDefectType?.id}
          onSelect={onSelectDefectType}
          disabled={!canUserEditAnnotation}
        />
      )}
    </DefectWrapper>
  )
}

export default DefectSelectContainer

const DefectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  height: 100%;
`
