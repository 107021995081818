import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import { format, isValid, parseISO } from 'date-fns'
import { ESlidesCount } from 'features/cases-management/types/TCasePagination'
import { ECaseStage } from 'features/cases-management/ui/cases-table/modal/StatusModal'
import { TDateRange } from 'features/uploaded-file/lib/common'
import i18next from 'shared/lib/i18n/i18n'
import { ECaseStageQuery } from 'types/ICase'
import { DictionaryItem } from 'types/IDictionary'
import IUser from 'types/IUser'

export const t = i18next.t

/**
 * Пропсы для компонента заголовка подменю.
 */
export interface ISubMenuTitleProps {
  /** Текстовое значение заголовка подменю. */
  title: string
  /** Активен ли фильтр. */
  isActive?: boolean
  /** Открыта ли меню во вьювере дефектов. */
  isDefectsViewer?: boolean
}

/**
 * Элемент подменю, представляющий отдельный фильтр.
 */
export interface ISubMenuItem {
  /** Уникальный ключ элемента подменю */
  key: string
  /** Текстовое значение для отображения элемента подменю. */
  label: string
  /** Значение, связанное с элементом фильтра. */
  queryValue?: string | boolean | number
}

/**
 * Подменю, содержащее элементы фильтров.
 */
export interface ISubMenu {
  /** Уникальный ключ подменю. */
  key: string
  /** Тип фильтра в подменю. */
  filterType: FilterType
  /** Заголовок подменю. */
  title: string
  /** Элементы фильтров в подменю. */
  items: ISubMenuItem[]
}

/**
 * Пропсы для компонента выпадающего списка.
 */
export interface IDropdownProps {
  /** Флаг, указывающий, открыт ли выпадающий список. */
  isOpen: boolean
  /** Флаг, указывающий, выбраны ли фильтры в списке. */
  isFiltersPicked: boolean
}

/**
 * Выбранный фильтр с его параметрами.
 */
export interface IPickedFilter {
  /** Название выбранного фильтра. */
  filterName: string
  /** Уникальный ключ выбранного фильтра. */
  key: string
  /** Значение выбранного фильтра (для фильтров типа чекбокс). */
  filterValue?: boolean
  /** Диапазон дат (для фильтров типа диапазона дат). */
  dateRange?: TDateRange | null
  /** Значение запроса, связанное с выбранным фильтром. */
  queryValue?: string | boolean | number
}

/**
 * Активные фильтры сгруппированные по заголовку.
 */
export interface IActiveFilter {
  filterType: FilterType
  /** Заголовок группы активных фильтров.  */
  title: string
  /** Уникальный ключ группы активных фильтров. */
  key: string
  /** Список активных фильтров в группе. */
  activeFilters: IPickedFilter[]
}

/**
 * Перечисление для типа даты.
 * @readonly
 * @enum {string}
 */
export enum DateType {
  FROM = 'from',
  TO = 'to',
}

export enum FilterType {
  CHECKBOX = 'Checkbox',
  DATA_RANGE = 'DataRange',
  SINGLE_SELECT = 'SingleSelect',
}

export enum FilterQueryKey {
  QUERY = 'query',
  URGENT = 'urgent',
  STAGE = 'stage',
  SLIDES_COUNT = 'slidesCount',
  DIAGNOSTIC_PROCEDURE_TYPE_ID = 'diagnosticProcedureTypeId',
  DATE_REGISTRATION = 'dateRegistration',
  CASE_RESEARCH_TYPE_ID = 'caseResearchTypeId',
  ASSIGNED_DOCTORS = 'assignedDoctorUserId',
  SLIDE_DEFECT = 'containsDefect',
  FALSE_POSITIVE = 'falsePositive',
  DEFECT_TYPE_ID = 'defectTypeId',
  STAIN_ID = 'stainId',
  DEFECT_DATE = 'createdAt',
  DEFECT_CREATED_BY = 'defectCreatedBy',
}

export enum DataFilterQuery {
  FROM = 'caseDateFrom',
  TO = 'caseDateTo',
}

export enum DefectCreatedByFilterQuery {
  USER = 'USER',
  ML_MODEL = 'ML',
}

export enum DefectsDataFilterQuery {
  FROM = 'defectCreatedFrom',
  TO = 'defectCreatedTo',
}

export interface ITableRequestConfig {
  [key: string]: string | boolean | undefined | string[] | number[] | number
}

export interface FilterBubbleProps {
  activeFilter: IActiveFilter
  handleRemoveFilter: (key: string) => void
  handleFilterChange: (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => void
  isFilterActive: (itemKey: string) => boolean
  handleDataRangeChange: (dates: [Date | null, Date | null] | null, dateStrings: [string, string]) => void
  menuConfig: ISubMenu[]
  dateRangeFilter?: TDateRange | null
}

export const menuConfig: ISubMenu[] = [
  {
    filterType: FilterType.SINGLE_SELECT,
    items: [
      { key: `${FilterQueryKey.SLIDES_COUNT}:1`, label: t('Ноль слайдов'), queryValue: ESlidesCount.ZERO },
      { key: `${FilterQueryKey.SLIDES_COUNT}:2`, label: t('Хотя бы 1 слайд'), queryValue: ESlidesCount.GTE_ONE },
      { key: `${FilterQueryKey.SLIDES_COUNT}:3`, label: t('Меньше 80% слайдов'), queryValue: ESlidesCount.LT_PCT80 },
      { key: `${FilterQueryKey.SLIDES_COUNT}:4`, label: t('Больше 80% слайдов'), queryValue: ESlidesCount.GTE_PCT80 },
      { key: `${FilterQueryKey.SLIDES_COUNT}:5`, label: t('100% слайдов'), queryValue: ESlidesCount.PCT100 },
    ],
    key: FilterQueryKey.SLIDES_COUNT,
    title: t('Количество стекол в случае'),
  },
  {
    filterType: FilterType.SINGLE_SELECT,
    items: [
      { key: `${FilterQueryKey.URGENT}:1`, label: t('Срочно'), queryValue: true },
      { key: `${FilterQueryKey.URGENT}:2`, label: t('Несрочно'), queryValue: false },
    ],
    key: FilterQueryKey.URGENT,
    title: t('Срочность'),
  },
  {
    filterType: FilterType.CHECKBOX,
    items: [
      { key: `${FilterQueryKey.STAGE}:1`, label: t(ECaseStage.OPEN), queryValue: ECaseStageQuery.OPEN },
      {
        key: `${FilterQueryKey.STAGE}:2`,
        label: t(ECaseStage.WAITING_SLIDES),
        queryValue: ECaseStageQuery.WAITING_SLIDES,
      },
      {
        key: `${FilterQueryKey.STAGE}:3`,
        label: t(ECaseStage.ON_DISTRIBUTION),
        queryValue: ECaseStageQuery.ON_DISTRIBUTION,
      },
      { key: `${FilterQueryKey.STAGE}:4`, label: t(ECaseStage.ASSIGNED), queryValue: ECaseStageQuery.ASSIGNED },
      {
        key: `${FilterQueryKey.STAGE}:5`,
        label: t(ECaseStage.ON_DISTRIBUTION_DECLINED),
        queryValue: ECaseStageQuery.ON_DISTRIBUTION_DECLINED,
      },
      { key: `${FilterQueryKey.STAGE}:6`, label: t(ECaseStage.COMPLETED), queryValue: ECaseStageQuery.COMPLETED },
    ],
    key: FilterQueryKey.STAGE,
    title: t('Статус'),
  },
  {
    filterType: FilterType.DATA_RANGE,
    items: [{ key: `${FilterQueryKey.DATE_REGISTRATION}:1`, label: t('Дата регистрации в ЛИС') }],
    key: FilterQueryKey.DATE_REGISTRATION,
    title: t('Дата регистрации в ЛИС'),
  },
  {
    filterType: FilterType.DATA_RANGE,
    items: [{ key: `${FilterQueryKey.DEFECT_DATE}:1`, label: t('Дата обнаружения дефекта') }],
    key: FilterQueryKey.DEFECT_DATE,
    title: t('Дата обнаружения дефекта'),
  },
  {
    filterType: FilterType.SINGLE_SELECT,
    items: [
      {
        key: `${FilterQueryKey.FALSE_POSITIVE}:1`,
        label: t('Да'),
        queryValue: true,
      },
      {
        key: `${FilterQueryKey.FALSE_POSITIVE}:2`,
        label: t('Нет'),
        queryValue: false,
      },
    ],
    key: FilterQueryKey.FALSE_POSITIVE,
    title: t('Ложная детекция'),
  },
  {
    filterType: FilterType.SINGLE_SELECT,
    items: [
      {
        key: `${FilterQueryKey.SLIDE_DEFECT}:1`,
        label: t('С дефектами'),
        queryValue: true,
      },
      { key: `${FilterQueryKey.SLIDE_DEFECT}:2`, label: t('Без дефектов'), queryValue: false },
    ],
    key: FilterQueryKey.SLIDE_DEFECT,
    title: t('Дефект'),
  },
  {
    filterType: FilterType.SINGLE_SELECT,
    items: [
      {
        key: `${FilterQueryKey.DEFECT_CREATED_BY}:1`,
        label: t('Пользователь'),
        queryValue: DefectCreatedByFilterQuery.USER,
      },
      {
        key: `${FilterQueryKey.DEFECT_CREATED_BY}:2`,
        label: t('ML - модель'),
        queryValue: DefectCreatedByFilterQuery.ML_MODEL,
      },
    ],
    key: FilterQueryKey.DEFECT_CREATED_BY,
    title: t('Кто обнаружил'),
  },
]

/** Удаляет подменю "Назначенные врачи" из конфигурации, если оно существует. */
export const removeAssignedDoctors = (config: ISubMenu[]) => {
  const index = config.findIndex((item) => item.key === FilterQueryKey.ASSIGNED_DOCTORS)
  if (index !== -1) {
    config.splice(index, 1)
  }
}

/**
 * Создает подменю для назначенных врачей.
 *
 * @param {IUser[]} doctorsList - Список врачей для включения в подменю.
 * @param {string} title - Заголовок подменю.
 * @returns {ISubMenu} Созданная конфигурация подменю.
 */
export const createAssignedDoctorsMenu = (doctorsList: IUser[], title: string): ISubMenu => ({
  filterType: FilterType.CHECKBOX,
  items: doctorsList.map((doctor) => ({
    key: `${FilterQueryKey.ASSIGNED_DOCTORS}:${doctor.userId}`,
    label: doctor.fullname,
    queryValue: doctor.userId,
  })),
  key: FilterQueryKey.ASSIGNED_DOCTORS,
  title: t(title),
})

export const createSubMenu = (data: DictionaryItem[], key: string, title: string): ISubMenu => ({
  filterType: FilterType.CHECKBOX,
  items: data?.map((item) => ({
    key: `${key}:${String(item.id)}`,
    label: t(item.name),
    queryValue: item.id || undefined,
  })),
  key,
  title: t(title),
})

/**
 * Преобразует дату для использования в запросе.
 * @param {string | null} inputDate - Входная дата.
 * @param {DateType} dateType - Тип даты ('from' или 'to').
 * @returns {string} Преобразованная дата для запроса.
 */

export function convertDateForQuery(inputDate: string | null, dateType: DateType): string {
  if (inputDate === null) return ''

  const date = parseISO(inputDate)
  if (!isValid(date)) return ''

  if (dateType === 'from') {
    return format(date, "yyyy-MM-dd'T'00:00:00+03:00")
  }

  if (dateType === 'to') {
    return format(date, "yyyy-MM-dd'T'23:59:59+03:00")
  }
  return ''
}

/** Обновление стейта уникальный селекта в фильтрах */
const updateSingleSelectFilter = (
  prevFilters: IActiveFilter[],
  item: ISubMenuItem,
  subMenu: ISubMenu,
): IActiveFilter[] => {
  const { key: subMenuKey, title: subMenuTitle } = subMenu
  const filterIndex = prevFilters.findIndex((filter) => filter.key === subMenuKey)

  if (filterIndex !== -1) {
    const updatedFilters = [...prevFilters]

    if (item.key === updatedFilters[filterIndex].activeFilters[0].key) {
      updatedFilters.splice(filterIndex, 1)
      return updatedFilters
    }

    const updatedFilter = {
      ...updatedFilters[filterIndex],
      activeFilters: [
        {
          filterName: t(item.label),
          filterValue: true,
          key: item.key,
          queryValue: item.queryValue,
        },
      ],
    }

    updatedFilters[filterIndex] = updatedFilter
    return updatedFilters
  } else {
    return [
      ...prevFilters,
      {
        activeFilters: [
          {
            filterName: t(item.label),
            filterValue: true,
            key: item.key,
            queryValue: item.queryValue,
          },
        ],
        filterType: subMenu.filterType,
        key: subMenuKey,
        title: t(subMenuTitle),
      },
    ]
  }
}

const updateDateRangeFilter = (
  prevFilters: IActiveFilter[],
  filterKey: string,
  dateRange?: [string, string],
): IActiveFilter[] => {
  if (!dateRange) {
    return prevFilters.filter((f) => f.key !== filterKey)
  }
  const filterIndex = prevFilters.findIndex((filter) => filter.key === filterKey)
  if (filterIndex !== -1) {
    const updatedFilters = prevFilters.map((filter, index) => {
      if (index === filterIndex) {
        return {
          ...filter,
          activeFilters: [
            {
              dateRange: { from: dateRange[0], to: dateRange[1] },
              filterName: filter.title,
              filterValue: true,
              key: 'dateRange',
            },
          ],
        }
      }
      return filter
    })

    return updatedFilters
  } else {
    const filterTitle = t(
      filterKey === FilterQueryKey.DEFECT_DATE ? 'Дата обнаружения дефекта' : 'Дата регистрации в ЛИС',
    )
    return [
      ...prevFilters,
      {
        activeFilters: [
          {
            dateRange: { from: dateRange[0], to: dateRange[1] },
            filterName: filterTitle,
            filterValue: true,
            key: filterKey,
          },
        ],
        filterType: FilterType.DATA_RANGE,
        key: filterKey,
        title: filterTitle,
      },
    ]
  }
}

export const updateFilters = (
  prevFilters: IActiveFilter[],
  item: ISubMenuItem | null,
  value: boolean | string,
  subMenu: ISubMenu,
  dateRange?: [string, string],
): IActiveFilter[] => {
  // Обработка изменения датаренж фильтра
  if (subMenu.filterType === FilterType.DATA_RANGE) {
    return updateDateRangeFilter(prevFilters, subMenu.key, dateRange)
  }

  if (subMenu.filterType === FilterType.SINGLE_SELECT && item) {
    return updateSingleSelectFilter(prevFilters, item, subMenu)
  }

  const indexAlreadyPickedFilter = prevFilters.findIndex((filter) => filter.key === subMenu.key)

  if (indexAlreadyPickedFilter !== -1) {
    // Если фильтр уже выбран, обновляем его активные значения
    const pickedSubmenu = { ...prevFilters[indexAlreadyPickedFilter] }
    let activeFilters = [...pickedSubmenu.activeFilters]

    // Обработка обычных фильтров
    const existingFilterIndex = activeFilters.findIndex((f) => f.key === item?.key)
    if (value && item?.label) {
      if (existingFilterIndex === -1) {
        // Если фильтр новый, добавляем его
        activeFilters.push({
          filterName: item.label,
          filterValue: value as boolean,
          key: item.key,
          queryValue: item.queryValue,
        })
      } else {
        // Если фильтр уже существует, обновляем его значение
        activeFilters[existingFilterIndex].filterValue = value as boolean
      }
    } else {
      // Если значение фильтра false, удаляем его из списка активных фильтров
      activeFilters = activeFilters.filter((f) => f.key !== item?.key)
    }

    pickedSubmenu.activeFilters = activeFilters

    if (activeFilters.length === 0) {
      // Если после обновления в подменю не осталось активных фильтров, удаляем подменю из списка
      return prevFilters.filter((_, index) => index !== indexAlreadyPickedFilter)
    } else {
      // Обновляем список фильтров с новыми значениями для активных фильтров
      return prevFilters.map((filter, index) => (index === indexAlreadyPickedFilter ? pickedSubmenu : filter))
    }
  } else if (value) {
    // Добавление нового фильтра, если он был активирован и ранее не был выбран
    return [
      ...prevFilters,
      {
        activeFilters: [
          {
            filterName: item!.label,
            filterValue: value as boolean,
            key: item!.key,
            queryValue: item?.queryValue,
          },
        ],
        filterType: subMenu.filterType,
        key: subMenu.key,
        title: subMenu.title,
      },
    ]
  }

  // Возвращаем предыдущие фильтры, если не было изменений
  return prevFilters
}
