import { useTypedSelector } from 'app/redux/lib/selector'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { TQueryParams } from 'features/cases-management/types/TCasePagination'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { useDefectsTableQuery } from 'features/defects/api/query'
import defectsService from 'features/defects/api/service'
import { EDefectsTableType } from 'features/defects/types/TDefectsPagination'
import { transformFilters } from 'features/thumbnails/common/utils'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectDefectsTabUrl } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { TABS_QUERY_PARAMS_KEY } from 'shared/lib/common/storageKeys'
import { getFromLocalStorage } from 'shared/lib/local-storage'
import { TCasesManagementTabs } from 'types/TTab'

const DEFAULT_PAGE_SIZE = 20

export const useSlidesDefects = (tabType?: TCasesManagementTabs, tableQuery?: string) => {
  const { tabsQueryParams } = useCaseManagementTabContext()
  const localTabsQueryParams: TQueryParams = getFromLocalStorage(TABS_QUERY_PARAMS_KEY) || {}
  const allRoutsQueryParams = tabsQueryParams || localTabsQueryParams
  const workspaceId = useCurrentWorkspaceId()
  const defectsTabUrl = useSelector(selectDefectsTabUrl) as TCasesManagementTabs
  const currentTab = defectsTabUrl ?? tabType
  const defectsFilters = useTypedSelector((state) => state.viewerPage.defectsFilters)
  const filters = transformFilters(defectsFilters)
  const [cursor, setCursor] = useState<string | undefined>()

  if (tableQuery) {
    filters.query = tableQuery
  }

  const {
    defectsList,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage: queryHasNextPage,
    hasPreviousPage,
    isFetching,
    isLoading: isLoadingDefects,
  } = useDefectsTableQuery(
    currentTab as EDefectsTableType,
    DEFAULT_PAGE_SIZE,
    allRoutsQueryParams[ECaseTableType.DEFECTS]?.[currentTab]?.queryParams,
    cursor,
    filters,
  )

  const slides = defectsList.map((defect) => ({
    ...defect.slide,
    caseId: defect.caseId,
  }))
  const slidesIds = defectsList.map((defect) => defect.slide.slideId)

  const fetchPrevCursor = async (slideId: number) => {
    const { previousPageCursor } = await defectsService.getNextPrevDefectsCursor(Number(workspaceId), slideId)

    return previousPageCursor
  }

  // Функция для загрузки следующей страницы
  const fetchMoreSlides = useCallback(async () => {
    if (queryHasNextPage) {
      await fetchNextPage()
      fetchPrevCursor(defectsList[defectsList.length - 1]?.slide.slideId).then((cursor) => {
        if (cursor) setCursor(cursor)
      })
    }
  }, [queryHasNextPage, defectsList, fetchNextPage])

  return {
    defectsList,
    fetchMoreSlides,
    fetchPreviousPage,
    hasPreviousPage,
    isFetching,
    isLastPage: !queryHasNextPage,
    isLoadingDefects,
    slides,
    slidesIds,
  }
}
